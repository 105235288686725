import axios from "@/config/AxiosConfig";

function sailAvail(request) {
  let url = "/api/v3/sales/shopping/cruise/cruise-sail-avail";
  return axios.post(url, request, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function itineraryDesc(request) {
  let url = "/api/v3/sales/shopping/cruise/cruise-itinerary-desc";
  return axios.post(url, request, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function categoryAvail(request) {
  let url = "/api/v3/sales/shopping/cruise/cruise-category-avail";
  return axios.post(url, request, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function cabinAvail(request) {
  let url = "/api/v3/sales/shopping/cruise/cruise-cabin-avail";
  return axios.post(url, request, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function cabinDetail(request) {
  let url = "/api/v3/sales/shopping/cruise/cruise-cabin-detail";
  return axios.post(url, request, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function cabinHold(request) {
  let url = "/api/v3/sales/shopping/cruise/cruise-cabin-hold";
  return axios.post(url, request, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function cabinUnhold(request) {
  let url = "/api/v3/sales/shopping/cruise/cruise-cabin-unhold";
  return axios.post(url, request, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function diningAvail(request) {
  let url = "/api/v3/sales/shopping/cruise/cruise-dining-avail";
  return axios.post(url, request, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function priceBooking(request) {
  let url = "/api/v3/sales/shopping/cruise/cruise-price-booking";
  return axios.post(url, request, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function booking(request) {
  let url = "/api/v3/sales/shopping/cruise/cruise-book";
  return axios.post(url, request, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  sailAvail,
  itineraryDesc,
  categoryAvail,
  cabinAvail,
  cabinDetail,
  cabinHold,
  cabinUnhold,
  diningAvail,
  priceBooking,
  booking,
};
