import state from "@/store/modules/cartransfer/state";
import getters from "@/store/modules/cartransfer/getters";
import actions from "@/store/modules/cartransfer/actions";
import mutations from "@/store/modules/cartransfer/mutations";

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
