import Dayjs from "dayjs";

// 使用dayjs格式化日期对象
function formatDate(value, formatStr) {
  if (!formatStr) {
    formatStr = "YYYY-MM-DD";
  }
  return Dayjs(value).format(formatStr);
}

function formatAmOrPm(time) {
  let type = Number(time.split(":")[0]) < 12 ? "AM" : "PM";

  return time + " " + type;
}

function formatDateTime(time) {
  let date = new Date(time);
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let minute = date.getMinutes();
  minute = minute < 10 ? "0" + minute : minute;

  return y + "-" + m + "-" + d + " " + h + ":" + minute;
}

function add(date, num, unit, formatStr) {
  if (!formatStr) {
    formatStr = "YYYY-MM-DD";
  }
  return Dayjs(date).add(num, unit).format(formatStr);
}

function format(date) {
  let fullYear = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  let dayStr = "";
  if (day < 10) {
    dayStr = "0" + day;
  } else {
    dayStr = day;
  }

  let monStr = "";
  if (month < 10) {
    monStr = "0" + month;
  } else {
    monStr = month;
  }

  let dateStr = fullYear + "-" + monStr + "-" + dayStr;
  return dateStr;
}

function getDefaultDateByPreDate(preDate) {
  let defaultDate = new Date(
    preDate.getFullYear(),
    preDate.getMonth(),
    preDate.getDate() + 1,
    preDate.getHours(),
    preDate.getMinutes(),
    preDate.getSeconds(),
    preDate.getMilliseconds()
  );
  return format(defaultDate);
}

function getDefaultDateByOffset(offset) {
  let today = new Date();
  let offsetDate = new Date(today);
  offsetDate.setDate(offsetDate.getDate() + offset);
  return format(offsetDate);
}

function validateDefaultDate(date) {
  let nowDate = Date.now();
  let defaultDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  );
  if (nowDate > date) {
    return format(nowDate);
  }
  return format(defaultDate);
}

/*
 * 年份
 * */
function getDurationByDates(startDate, endDate) {
  let startYear = startDate.getFullYear();
  let startMonth = startDate.getMonth();

  let endYear = endDate.getFullYear();
  let endMonth = endDate.getMonth();

  let yearsDec = endYear - startYear;
  if (endMonth > startMonth) yearsDec--;
  return yearsDec;
}

function getDayDurationByDates(startDate, endDate) {
  return parseInt(
    Math.abs(new Date(endDate) - new Date(startDate)) / 1000 / 60 / 60 / 24
  );
}

function dateShortcuts() {
  return [
    {
      text: "Next week",
      value: () => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
        return [end, start];
      },
    },
    {
      text: "Next month",
      value: () => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
        return [end, start];
      },
    },
    {
      text: "Next 3 months",
      value: () => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() + 3600 * 1000 * 24 * 90);
        return [end, start];
      },
    },
  ];
}

function lastDateShortcuts() {
  return [
    {
      text: "Last week",
      value: () => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        return [start, end];
      },
    },
    {
      text: "Last month",
      value: () => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        return [start, end];
      },
    },
    {
      text: "Last 3 months",
      value: () => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        return [start, end];
      },
    },
  ];
}

function validateCheckDate(rule, value, callback) {
  if (!value[0] && !value[1]) {
    callback(new Error("Please enter check date."));
  } else {
    callback();
  }
}

function getLastYear(m) {
  if (parseInt(m) > 0) {
    //m是 往前推的总月份
    let myDate = new Date();
    // var mytime=formatDate('yyyy-MM-dd');//获取当前日期
    let year = myDate.getFullYear(); //获取当前年
    let month = myDate.getMonth() + 1; //获取当前月
    let day = myDate.getDate(); //获取当前日
    let lowData = ""; //当前年月日往前推m个月后获取到的年月日
    let ylow = parseInt(parseInt(m) / 12); //往前推的总月份换成对应的年数取整
    let mlow = parseInt(m) % 12; //往前推的月数
    if (ylow > 0) {
      year -= ylow; //年要再减一
    }
    if (mlow > month || mlow == month) {
      //往前推的月份大于或等于当前月份，12减去往前推的月份加上现在的月份
      year = year - 1;
      month = 12 - mlow + month;
      lowData = year + "/0" + month + "/" + day;
    } else {
      //往前推的月份小于当前月份
      month -= mlow;
      lowData = year + "/0" + month + "/" + day;
    }
    return new Date(lowData).getTime();
  }
}

function getShortDate() {
  var dt = new Date();
  var m = new Array(
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Spt",
    "Oct",
    "Nov",
    "Dec"
  );
  // var w=new Array("Monday","Tuseday","Wednesday","Thursday","Friday","Saturday","Sunday");
  // var d=new Array("st","nd","rd","th");
  var mn = dt.getMonth();
  // var wn=dt.getDay();
  var dn = dt.getDate();
  // var dns;
  // if(((dn%10)<1) ||((dn%10)>3)){
  //     dns=d[3];
  // }
  // else
  // {
  //     dns=d[(dn%10)-1];
  //     if((dn==11)||(dn==12)){
  //         dns=d[3];
  //     }
  // }
  return m[mn] + " " + dn + ", " + dt.getFullYear();
}

function getOffsetDate(date, offset) {
  date = date == null ? new Date() : date;
  let offsetDate = new Date(date);
  offsetDate.setDate(offsetDate.getDate() + offset);
  return format(offsetDate);
}

export default {
  formatDate,
  getOffsetDate,
  validateDefaultDate,
  add,
  format,
  getDefaultDateByPreDate,
  getDefaultDateByOffset,
  getDurationByDates,
  getDayDurationByDates,
  dateShortcuts,
  lastDateShortcuts,
  validateCheckDate,
  getLastYear,
  getShortDate,
  formatDateTime,
  formatAmOrPm,
};
