import state from "@/store/modules/account/state";
import getters from "@/store/modules/account/getters";
import actions from "@/store/modules/account/actions";
import mutations from "@/store/modules/account/mutations";

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
