<template>
  <el-footer class="footer">
    <div class="main-footer-sub">
      <div class="container">
        <el-row class="sub-row">
          <el-col :span="14" class="left-main-footer-sub">
            <!--                        <el-form :model="subscribe" :inline="true">-->
            <!--                            <el-form-item class="subscribe-form">-->
            <!--                                <label class="i18n footer-subscribe">{{$t('SUBSCRIBE TO OUR NEWSLETTER')}}</label>-->
            <!--                                <el-input v-model="subscribe.email" class="" placeholder="Enter Your Email">-->
            <!--                                    <template #append>-->
            <!--                                        <i class="fa fa-paper-plane"></i>{{$t('SUBSCRIBE')}}-->
            <!--                                    </template>-->
            <!--                                </el-input>-->
            <!--                            </el-form-item>-->
            <!--                        </el-form>-->
          </el-col>
          <el-col :span="10" class="right-main-footer-sub">
            <ul class="social-media">
              <li>
                <a class="facebook" href="#"><i class="fa fa-facebook"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-instagram"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-google-plus"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-youtube"></i></a>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="main-footer">
      <div class="container">
        <el-row>
          <el-col :span="11" class="about-box">
            <h3 class="i18n" name="Footer-Popular">
              {{ $t("common.terms-and-conditions") }}
            </h3>
            <p class="i18n" name="Footer-Description">
              {{ $t("common.agreement-between-customer-and-sesamedoortravel") }}
            </p>
            <a href="https://developer.expediapartnersolutions.com/terms/en">{{
              $t("common.supplier-terms-and-conditions")
            }}</a>
            <p>
              {{ $t("common.supplier-terms-and-conditions-content") }}
            </p>
          </el-col>
          <el-col :span="2" class="about-box"></el-col>
          <!--          <el-col :span="6" class="links">-->
          <!--            <h4 class="i18n" name="Footer-Popular">-->
          <!--              {{ $t("Popular Things to do") }}-->
          <!--            </h4>-->
          <!--            <ul>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="Popular-Tours-1">{{-->
          <!--                  $t("Romantic France")-->
          <!--                }}</a>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="Popular-Tours-2">{{-->
          <!--                  $t("Wonderful Lodnon")-->
          <!--                }}</a>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="Popular-Tours-3">{{-->
          <!--                  $t("Awesome Amsterdam")-->
          <!--                }}</a>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="Popular-Tours-4">{{-->
          <!--                  $t("Wild Africa")-->
          <!--                }}</a>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="Popular-Tours-5">{{-->
          <!--                  $t("Beach Goa")-->
          <!--                }}</a>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="Popular-Tours-6">{{-->
          <!--                  $t("Casino Los Vages")-->
          <!--                }}</a>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="Popular-Tours-7">{{-->
          <!--                  $t("Romantic France")-->
          <!--                }}</a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </el-col>-->
          <!--          <el-col :span="6" class="links">-->
          <!--            <h4 class="i18n" name="Footer-OurServies">-->
          <!--              {{ $t("Our Services") }}-->
          <!--            </h4>-->
          <!--            <ul>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="Footer-OurServies-1">{{-->
          <!--                  $t("Domestic Flights")-->
          <!--                }}</a>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="Footer-OurServies-2">{{-->
          <!--                  $t("International Flights")-->
          <!--                }}</a>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="Footer-OurServies-3"-->
          <!--                  >{{ $t("Tours") }} &amp; {{ $t("Holidays") }}</a-->
          <!--                >-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="Footer-OurServies-4">{{-->
          <!--                  $t("Domestic Hotels")-->
          <!--                }}</a>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="Footer-OurServies-5">{{-->
          <!--                  $t("International Hotels")-->
          <!--                }}</a>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="Footer-OurServies-6">{{-->
          <!--                  $t("Cruise Holidays")-->
          <!--                }}</a>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="Footer-OurServies-7">{{-->
          <!--                  $t("Car Rental")-->
          <!--                }}</a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </el-col>-->
          <el-col :span="11" class="contact-box">
            <h4 class="i18n" name="ContactUs">{{ $t("common.contact-us") }}</h4>
            <p class="i18n" name="ContactUs-Address">
              <i class="fa fa-home"></i> {{ $t("common.contact-address") }}
            </p>
            <p><i class="fa fa-phone"></i> +852 2439 9038</p>
            <p class="i18n" name="ContactUs-Email">
              <i class="fa fa-envelope-o"></i> sam@sesamedoortravel.com
            </p>
          </el-col>
        </el-row>
        <!--        <el-row class="we-accept">-->
        <!--          <el-col :span="24">-->
        <!--            <h4 class="i18n" name="WeAccept">{{ $t("We Accept") }}</h4>-->
        <!--            <ul>-->
        <!--              <li><img src="@/assets/images/card/card.jpg" alt="cruise" /></li>-->
        <!--              <li>-->
        <!--                <img src="@/assets/assets/images/card/card.jpg" alt="cruise" />-->
        <!--              </li>-->
        <!--              <li>-->
        <!--                <img src="@/assets/assets/images/card/card.jpg" alt="cruise" />-->
        <!--              </li>-->
        <!--              <li>-->
        <!--                <img src="@/assets/assets/images/card/card.jpg" alt="cruise" />-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
      </div>
    </div>
    <div class="main-footer-nav">
      <div class="container">
        <el-row class="footer-nav">
          <el-col :span="12" class="left-main-footer-nav">
            <p>
              {{ $t("common.contact-address", { year: getNowYear() }) }}
            </p>
          </el-col>
          <!--          <el-col :span="12" class="text-right right-main-footer-nav">-->
          <!--            <ul>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="FOOTERFLIGHTS">{{-->
          <!--                  $t("FLIGHTS")-->
          <!--                }}</a>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="FOOTERTOURS">{{-->
          <!--                  $t("Things to do")-->
          <!--                }}</a>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="FOOTERCARS">{{ $t("CARS") }}</a>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="FOOTERHOTELS">{{-->
          <!--                  $t("HOTELS")-->
          <!--                }}</a>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a href="#" class="i18n" name="FOOTERBLOG">{{ $t("BLOG") }}</a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </el-col>-->
          <div class="go-up">
            <!-- <a href="#"><i class="fa fa-arrow-up"></i></a> -->
            <el-backtop :bottom="100">
              <!-- <div
                                style="
                                    height: 100%;
                                    width: 100%;
                                    background-color: var(--el-bg-color-overlay);
                                    box-shadow: var(--el-box-shadow-lighter);
                                    text-align: center;
                                    line-height: 40px;
                                    color: #1989fa;
                                "
                            >
                                UP
                            </div> -->
              <a><i class="fa fa-arrow-up"></i></a>
            </el-backtop>
          </div>
        </el-row>
      </div>
    </div>
  </el-footer>
</template>
<script>
export default {
  name: "FooterMenu",
  data() {
    return {
      subscribe: {
        email: "",
      },
    };
  },
  methods: {
    getNowYear() {
      const date = new Date();
      return date.getFullYear();
    },
  },
};
</script>
<style lang="scss">
.footer {
  .main-footer-sub {
    padding: 45px 0px;
    background: #0a3152;
    color: #fff;

    .sub-row {
      // margin-left: 15px;
      // margin-right: 15px;
      .subscribe-form {
        label {
          display: inline-block;
          max-width: 100%;
          margin-bottom: 5px;
          font-weight: 700;
        }

        .el-input__wrapper {
          background: #07253f;
          border-radius: 0;
        }

        .el-input-group--append {
          height: 40px;
          --el-input-border-color: rgb(10, 49, 82);
        }

        .el-input-group__append {
          height: 40px;
          border: none;
          background: #f9676b;
          color: #fff;
          font-weight: bold;
          padding: 0 30px;
          cursor: pointer;
        }

        .fa-paper-plane {
          margin-right: 5px;
        }

        .el-input__inner {
          color: #fff;
        }
      }

      .social-media {
        padding: 0px;
        list-style-type: none;
        text-align: right;
        // margin-right: -15px;
        li {
          display: inline;

          a {
            background: #f9676b none repeat scroll 0 0;
            border-radius: 50%;
            color: #fff;
            display: inline-block;
            height: 16px;
            margin: 38px 5px 0;
            padding: 10px 10px;
            width: 16px;

            i.fa-facebook {
              position: relative;
              left: -4px;
            }
          }
        }
      }
    }
  }

  .main-footer {
    padding: 60px 0px;
    background: #07253f;

    .about-box {
      color: #bec4c8;
      line-height: 2;
      margin-bottom: 30px;

      h3 {
        font-weight: 800;
        margin-top: 0px;
        color: #f9676b;
        margin-bottom: 15px;
        letter-spacing: 1px;
        font-size: 18px;
      }

      a {
        display: inline-block;
        padding: 2px 15px;
        color: #f9676b;
        //border: 2px solid #f9676b;
        font-weight: bold;
        margin-top: 10px;
      }
    }

    .links {
      line-height: 2;
      margin-bottom: 30px;

      h4 {
        color: #f9676b;
        margin-top: 0px;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 15px;
        letter-spacing: 1px;
        font-size: 18px;
      }

      ul {
        list-style-type: none;
        padding: 0px;

        li {
          position: relative;

          &:before {
            content: "\f105";
            position: absolute;
            font-family: "FontAwesome";
            color: #bec4c8;
          }

          a {
            color: #bec4c8;
            margin-left: 15px;
          }
        }
      }
    }

    .contact-box {
      margin-bottom: 30px;

      h4 {
        color: #f9676b;
        margin-top: 0px;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 15px;
        letter-spacing: 1px;
      }

      p {
        color: #bec4c8;
        line-height: 2;

        i {
          border: 2px solid #bec4c8;
          border-radius: 50%;
          color: #f9676b;
          display: inline-block;
          height: 16px;
          margin-right: 10px;
          margin-top: 10px;
          padding: 10px 10px;
          width: 16px;
        }

        i.fa-home:before {
          position: relative;
          left: 1px;
        }

        i.fa-phone:before {
          position: relative;
          left: 2px;
          top: 1px;
        }
      }
    }

    .we-accept {
      text-align: center;
      padding: 30px 0px 0px;

      h4 {
        color: #f9676b;
        margin-top: 0px;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 15px;
        letter-spacing: 1px;
      }

      ul {
        list-style-type: none;
        padding: 0px;

        li {
          display: inline;
          margin: 0px 5px;
        }
      }
    }
  }

  .main-footer-nav {
    padding: 30px 0px;
    background: #0a3152;
    color: #bec4c8;
    position: relative;

    .container {
      position: relative;
    }

    p {
      margin-bottom: 0;
    }

    ul {
      margin-bottom: 0px;

      li {
        display: inline;
        margin: 0px 5px;

        a {
          color: #bec4c8;
          margin-left: 15px;
        }
      }
    }

    .go-up {
      // position: absolute;
      // right: -65px;
      // top: -7px;
      // a{
      //     color: #BEC4C8;
      //     background: #07253F none repeat scroll 0 0;
      //     border-radius: 50%;
      //     display: inline-block;
      //     height: 20px;
      //     padding: 5px;
      //     width: 20px;
      //     .fa-arrow-up{
      //         position: relative;
      //         left: 3px;
      //         top: 2px;
      //     }
      // }
      .el-backtop {
        width: 32px;
        height: 32px;

        .fa-arrow-up {
          color: #bec4c8;
        }
      }
    }
  }
}

.el-footer {
  height: auto;
  padding: 0;
}
</style>
