const state={
    profileReadRS: {},
    user: {
        username: '',
        surname: '',
        givenName: '',
        title: '',
        phoneNumber: '',
        emailAddress: '',
        address: ''
    }
};

export default state;
