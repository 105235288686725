import axios from "axios";
import AccountUtils from "@/utils/AccountUtils";

function authentication(username, password) {
    let formData = new URLSearchParams;
    formData.append("username", username);
    formData.append("password", password);

    let url = "/api/v1/account/authentication";
    return axios.post(url, formData,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    });
}

function loginStatus() {
    let url = '/api/v1/account/login-status';
    return axios.post(url, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function exit() {
    let url = "/api/v1/account/exit";
    return axios.post(url,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

function registry(givenName, surname, phoneNumber, emailAddress, username, password) {
    let profileCreateRQ = AccountUtils.getProfileCreateRQ(givenName, surname, phoneNumber, emailAddress, username, password);
    let url = "/api/v1/account/registry";
    return axios.post(url, profileCreateRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

function userInfo(username) {
    let profileReadRQ = AccountUtils.getProfileReadRQ(username);
    let url = "/api/v1/account/user-info";
    return axios.post(url, profileReadRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
    // return {
    //     "success": true,
    //     "profiles": {
    //         "profileInfos": [
    //             {
    //                 "profileList": [
    //                     {
    //                         "optInStatus": "OptedIn",
    //                         "profileType": null,
    //                         "customer": {
    //                             "gender": null,
    //                             "personName": {
    //                                 "namePrefix": null,
    //                                 "givenName": "Admin",
    //                                 "middleName": null,
    //                                 "surnamePrefix": null,
    //                                 "surname": "Bookingengine",
    //                                 "nameSuffix": null,
    //                                 "nameTitle": null,
    //                                 "document": null,
    //                                 "travelerRefNumber": null
    //                             },
    //                             "telephoneList": [
    //                                 {
    //                                     "phoneTechType": "Voice",
    //                                     "phoneUseType": null,
    //                                     "phoneNumber": "13800131211",
    //                                     "countryAccessCode": null
    //                                 }
    //                             ],
    //                             "emailList": [
    //                                 {
    //                                     "emailAddress": null,
    //                                     "stringValue": "admin2_bookingengine@skywidetech.com"
    //                                 }
    //                             ],
    //                             "addressList": [
    //                                 {
    //                                     "postalCode": null,
    //                                     "county": null,
    //                                     "countryCode": null,
    //                                     "countryName": {
    //                                         "code": null,
    //                                         "text": null
    //                                     },
    //                                     "addressLines": [
    //                                         "Shenzhen, Luohu"
    //                                     ]
    //                                 }
    //                             ]
    //                         },
    //                         "userIDList": [
    //                             {
    //                                 "type": null,
    //                                 "company_name": null,
    //                                 "pin_number": null,
    //                                 "hashed_pin_number": "P33asGg7lKfcseKkntFZrApqyWs=",
    //                                 "id": "admin2_bookingengine@skywidetech.com",
    //                                 "id_context": null
    //                             }
    //                         ],
    //                         "rph": "8562"
    //                     }
    //                 ]
    //             }
    //         ]
    //     }
    // }
}

function modification(username, oldPassword, newPassword, givenName, surname, phoneNumber, emailAddress, address) {

    let url = "/api/v1/account/modification";
    if (newPassword!=null && newPassword!=""){
        url = url+"?newPassword=newPassword";
    }
    let profileModifyRQ = AccountUtils.getProfileModifyRQ(username, oldPassword, givenName, surname, phoneNumber, emailAddress, address);
    return axios.post(url, profileModifyRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });

    // return {
    //     "success": true,
    //     "uniqueIDs": [
    //         {
    //             "type": null,
    //             "company_name": null,
    //             "id": "8562",
    //             "id_context": null
    //         }
    //     ]
    // }
}

function forgetPassword(email, callbackHostName) {
    let url =
        "/api/v3/security/login/userlogins/forget-password?userLoginId=" +
        email +
        "&callbackHostName=" +
        callbackHostName;
    return axios.get(url, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

function reactiveAccount(email, callbackHostName) {
    let url =
        "/api/v3/security/login/userlogins/reactivation?userLoginId=" +
        email +
        "&callbackHostName=" +
        callbackHostName;
    return axios.get(url, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}
export default {
    authentication,
    exit,
    registry,
    userInfo,
    modification,
    loginStatus,
    forgetPassword,
    reactiveAccount
}
