const getters = {
  cruiseViewItems(state) {
    return state.cruiseViewItems;
  },
  itineraryViewItem(state) {
    return state.itineraryViewItem;
  },
  categoryFaresItem(state) {
    return state.categoryFaresItem;
  },
  cruiseBookModel(state) {
    return state.cruiseBookModel;
  },
  cabinInfoList(state) {
    return state.cabinInfoList;
  },
  cabinDetailsInfo(state) {
    return state.cabinDetailsInfo;
  },
  diningOptions(state) {
    return state.diningOptions;
  },
  priceBookingDetails(state) {
    return state.priceBookingDetails;
  },
};

export default getters;
