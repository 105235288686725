import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./style/common.scss";
import "./style/animate.css";
import "font-awesome/css/font-awesome.min.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import toLoading from "./components/toLoading/toLoading.js";
import VueTelInput from "vue3-tel-input/dist/vue3-tel-input.common.js";
import "vue3-tel-input/dist/vue3-tel-input.css";
import GAuth from "vue3-google-oauth2";
import aircraft from "@/assets/i18n/aircraft";
import { createI18n } from "vue-i18n";
import airlineName from "@/assets/i18n/airlineName";
import airport_en from "@/assets/i18n/airport_en";
import airport_zh_HK from "@/assets/i18n/airport_zh_HK";
import airport_zh_CN from "@/assets/i18n/airport_zh_CN";
import common from "@/assets/i18n/common";

import "./style/media.scss";
import "./style/module.scss";

import "./style/page-media.scss";

router.beforeEach((to, from, next) => {
  store.dispatch("account/searchLoginStatus", {});
  next();
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

let app = createApp(App);
const VueTelInputOptions = {
  mode: "international",
  defaultCountry: "HK",
};
app.use(VueTelInput, VueTelInputOptions);

const i18n = createI18n({
  locale: sessionStorage.getItem("language") ? sessionStorage.getItem("language").split("-")[0] : "en",
  messages: {
    en: {
      aircraft: aircraft.messages.en,
      airlineName: airlineName.messages.en,
      airport: airport_en.messages,
      common: common.messages.en,
    },
    zh: {
      aircraft: aircraft.messages.zh_CN,
      airlineName: airlineName.messages.zh_CN,
      airport: airport_zh_CN.messages,
      common: common.messages.zh_CN,
    },
    hk: {
      aircraft: aircraft.messages.zh_HK,
      airlineName: airlineName.messages.zh_HK,
      airport: airport_zh_HK.messages,
      common: common.messages.zh_HK,
    },
  },
});


// const gAuthOptions = { clientId: '764595691158-9m3agl8de3n9jstvpkcd6ehd2s6av03u.apps.googleusercontent.com', scope: 'email', prompt: 'consent', fetch_basic_profile: false }
// app.use(GAuth, gAuthOptions);
app.use(i18n);
app.use(toLoading);
app.use(store).use(router).use(ElementPlus).mount("#app");

export default app;
