const state={
    bookingsRS: {},
    bookingsShow: {
        offset: 0,
        limit: 0,
        total: 0,
        resultList: []
    },
    bookingDetailsShow:{
        totalAmount: 0,
        necessaryInfo:{
            roomGuests:[],
            roomInfo:{
                pricePerRoom: 0,
                priceTotal: 0
            }
        }
    }
};

export default state;
