
const getters = {
    bookingsShow(state){
        return state.bookingsShow;
    },
    bookingDetailsShow(state){
        return state.bookingDetailsShow;
    }
};

export default getters;
