import * as mutationName from "@/store/modules/insurance/mutationTypes";
import InsuranceAPI from "@/apis/InsuranceAPI";
import { ElMessage } from "element-plus";

const actions = {
  quoteInsurance({ commit }, payload) {
    InsuranceAPI.quote(
      payload.requestorId,
      payload.passengerInfos,
      payload.destinations
    )
      .then((res) => {
        //console.info(res.data)
        commit(mutationName.UPDATE_QUOTE_INFO, {
          quoteRS: res.data,
        });
      })
      .catch((error) => {
        //console.info(error);
        ElMessage({
          showClose: true,
          message: error.message,
          type: "error",
        });
      });
  },
};

export default actions;
