import * as mutationName from "@/store/modules/cruise/mutationTypes";

const mutations = {
  [mutationName.UPDATE_CRUISE_VIEW_ITEMS](state, payload) {
    if (payload.cruiseViewItems) {
      state.cruiseViewItems = payload.cruiseViewItems;
    } else {
      state.cruiseViewItems = null;
    }
  },
  [mutationName.UPDATE_ITINERARY_VIEW_ITEM](state, payload) {
    if (payload.itineraryViewItem) {
      state.itineraryViewItem = payload.itineraryViewItem;
    } else {
      state.itineraryViewItem = null;
    }
  },
  [mutationName.UPDATE_CATEGORY_FARES_ITEM](state, payload) {
    if (payload.categoryFaresItem) {
      state.categoryFaresItem = payload.categoryFaresItem;
    } else {
      state.categoryFaresItem = null;
    }
  },
  [mutationName.UPDATE_CRUISE_BOOK_MODEL](state, payload) {
    if (payload.cruiseBookModel) {
      state.cruiseBookModel = payload.cruiseBookModel;
    } else {
      state.cruiseBookModel = null;
    }
  },
  [mutationName.UPDATE_CABIN_INFO_LIST](state, payload) {
    if (payload.cabinInfoList) {
      state.cabinInfoList = payload.cabinInfoList;
    } else {
      state.cabinInfoList = null;
    }
  },
  [mutationName.UPDATE_CABIN_DETAILS_INFO](state, payload) {
    if (payload.cabinDetailsInfo) {
      state.cabinDetailsInfo = payload.cabinDetailsInfo;
    } else {
      state.cabinDetailsInfo = null;
    }
  },
  [mutationName.UPDATE_CABIN_DINING_OPTIONS](state, payload) {
    if (payload.diningOptions) {
      state.diningOptions = payload.diningOptions;
    } else {
      state.diningOptions = null;
    }
  },
  [mutationName.UPDATE_CABIN_DINING_OPTIONS](state, payload) {
    if (payload.diningOptions) {
      state.diningOptions = payload.diningOptions;
    } else {
      state.diningOptions = null;
    }
  },
  [mutationName.UPDATE_PRICE_BOOKING_DETAILS](state, payload) {
    if (payload.priceBookingDetails) {
      state.priceBookingDetails = payload.priceBookingDetails;
    } else {
      state.priceBookingDetails = null;
    }
  },
};

export default mutations;
