export const RESET_STATE = "resetState";
export const UPDATE_CITY_HOTELS_AVAIL = "updateCityHotelsAvailRS";
export const UPDATE_HOTELS_SHOW = "updateHotelsShow";
export const UPDATE_HOTEL_DETAILS_AVAIL = "updateHotelDetailsRS";
export const UPDATE_HOTEL_DESCRIPTION = "updateHotelDescriptionRS";
export const UPDATE_CONFIRM = "updateComfirmRS";
export const UPDATE_PREBOOK = "updatePreBookRS";
export const UPDATE_BOOK = "updateBookRS";

/* 机票 */
export const UPDATE_FLIGHTS_INFO = "updateFlightsInfo";
export const UPDATE_FLIGHTS_SHOW = "updateFlightsShow";
export const CHANGE_SPECIFIC_TRIP_ITINERARIES_SHOW = "changeSpecificTripItineraries";
export const VERIFY_PRICE_FLIGHT_ITINERARY = "verifyPriceFlightItinerary";
export const UPDATE_FLIGHT_RULES = "updateFlightRules";
export const UPDATE_FLIGHT_OFFERS_SEATMAP = "updateFlightOffersSeatMap";
export const UPDATE_FLIGHT_BOOKING = "updateFlightBookRS";

