import DynamicPkgUtils from "@/utils/DynamicPkgUtils";
import axios from "@/config/AxiosConfig";

function avail(
  originCode,
  destCode,
  cabinPrefs,
  ttiCode,
  bestOnlyIndicator,
  checkInDate,
  checkOutDate,
  roomNum,
  adultNum,
  childNum,
  language,
  childAges
) {
  let pkgAvailRQ = DynamicPkgUtils.getAvailRQ(
    originCode,
    destCode,
    cabinPrefs,
    ttiCode,
    bestOnlyIndicator,
    checkInDate,
    checkOutDate,
    roomNum,
    adultNum,
    childNum,
    language,
    childAges
  );
  let url = "/api/v1/dynamicpkg/avail";
  ////console.info(hotelAvailRQ);
  return axios.post(url, pkgAvailRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function verifyPrice(
  airItinerary,
  roomStays,
  checkInDate,
  checkOutDate,
  passengerInfos,
  username,
  status,
  quoteId
) {
  let url = "/api/v1/dynamicpkg/verify-price";
  let packageResRQ = DynamicPkgUtils.getPackageResRQ(
    airItinerary,
    roomStays,
    checkInDate,
    checkOutDate,
    passengerInfos,
    username,
    status,
    quoteId
  );
  return axios.post(url, packageResRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function preBook(
  airItinerary,
  roomStays,
  checkInDate,
  checkOutDate,
  passengerInfos,
  username,
  status,
  quoteId,
  seatRequests,
  pricedOffers
) {
  let url = "/api/v1/dynamicpkg/prebook";
  let packageResRQ = DynamicPkgUtils.getPackageResRQ(
    airItinerary,
    roomStays,
    checkInDate,
    checkOutDate,
    passengerInfos,
    username,
    status,
    quoteId,
    seatRequests,
    pricedOffers
  );
  return axios.post(url, packageResRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function book(bookRQ) {
  let url = "/api/v1/dynamicpkg/book";
  return axios.post(url, bookRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  avail,
  verifyPrice,
  preBook,
  book,
};
