const state={
    cityHotelsAvailRS:{},
    hotelDetailsAvailRS:{},
    hotelDescriptionRS:{},
    confirmRS:{},
    preBookRS:{},
    bookRS:{},
    /*酒店列表页相关*/
    hotels: [],
    hotelsShow:[],
    resultNum: 0,
    minPrice: 0,
    maxPrice: 0,
    currencyCode: '',
    packagePreBookRQ: null,
    packageBookRQ: null,
    hotelReservation: {},
    hotelTotalPayment: 0,
    airReservation: {},
    flightTotalPayment: 0,
    /*酒店详情页相关*/
    hotelSelected:{
        rooms:{},
        images:[]
    },
    /*预定页相关*/
    packageBookingInfo:{
        basicInfo: {
            hotelName: '',
            roomType: '',
            checkIn: '',
            checkOut: '',
            roomNum: 0,
            guestPerRoom: '',
            durationDays: 0
        },
        passengerInfos: [],
        priceDetails: {
            pricePerRoom: 0,
            total: 0
        }
    },
    preBookRQ:  {},
    /*机票*/
    lowFareSearchRS: {},
    pricedItineraryInfos: [],
    offerMap: {},
    originDestinationShow:{},
    originDestinationMap: {},
    airlineOptions:[],
    departureAirportOptions:[],
    arrivalAirportOptions:[],
    minFlyingTime: 0,
    maxFlyingTime: 0,
    minFlightPrice: 0,
    maxFlightPrice: 0,
    flightItineraries: [],
    flightItinerariesShow: [],
    flightResultNum: 0,
    groupNumberShow: 0,
    verifyPricedItineraryInfo: {},
    fareRuleMap: {},
    offersRS: {},
    offers: [],
    passengerNumMap:{},
    airBookRS: {},
    packageTotalPayment: {}
};

export default state;
