const state={
    cityHotelsAvailRS:{},
    hotelDetailsAvailRS:{},
    hotelDescriptionRS:{},
    confirmRS:{},
    preBookRS:{},
    bookRS:{},
    /*列表页相关*/
    hotels: [],
    hotelsShow:[],
    resultNum: 0,
    minPrice: 0,
    maxPrice: 0,
    currencyCode: '',
    /*详情页相关*/
    hotelSelected:{
        rooms:{},
        images:[]
    },
    /*预定页相关*/
    hotelRoomBookingInfo:{
        basicInfo: {
            hotelName: '',
            roomType: '',
            checkIn: '',
            checkOut: '',
            roomNum: 0,
            guestPerRoom: '',
            durationDays: 0
        },
        passengerInfos: [],
        priceDetails: {
            pricePerRoom: 0,
            total: 0
        }
    },
    preBookRQ:  {},
    totalPayment: {
        currencyCode: '',
        amount: 0
    }
};

export default state;
