import axios from "@/config/AxiosConfig";
import CarTransferUtils from "@/utils/CarTransferUtils";

function groundAvail(
  tripType,
  pickupLocationType,
  pickupLocationCode,
  dropoffLocaitonType,
  dropoffLocationCode,
  adultNum,
  childNum,
  infantNum,
  pickupDatetime,
  returnDatetime,
  childAges
) {
  let groundAvailRQ = CarTransferUtils.getGroundAvailRQ(
    tripType,
    pickupLocationType,
    pickupLocationCode,
    dropoffLocaitonType,
    dropoffLocationCode,
    adultNum,
    childNum,
    infantNum,
    pickupDatetime,
    returnDatetime,
    childAges
  );
  //console.info(groundAvailRQ);
  let url = "/api/v1/ground/avail";
  return axios.post(url, groundAvailRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function groundRes(
  tripType,
  resStatus,
  holder,
  pickupInfo,
  departureInfo,
  confirmationIds
) {
  let groundResRQ = CarTransferUtils.getGroundBookRQ(
    tripType,
    resStatus,
    holder,
    pickupInfo,
    departureInfo,
    confirmationIds
  );
  let url = "/api/v1/ground/book";
  //console.info(url);
  //console.info(groundResRQ);
  return axios.post(url, groundResRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function groundResBook(groundResRQ) {
  let url = "/api/v1/ground/book";
  //console.info(url);
  //console.info(groundResRQ);
  return axios.post(url, groundResRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  groundAvail,
  groundRes,
  groundResBook,
};
