<template>
  <div
    id="loader"
    class="load-full-screen"
    :class="{ 'unshow-loading': !toLoadingObject.show }"
    :show="toLoadingObject.show"
  >
    <div class="loading-animation">
      <span><i class="fa fa-plane"></i></span>
      <span><i class="fa fa-bed"></i></span>
      <span><i class="fa fa-ship"></i></span>
      <span><i class="fa fa-suitcase"></i></span>
    </div>
    <div class="loading-text" v-show="toLoadingObject.text">
      {{ toLoadingObject.text }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    toLoadingObject: Object,
  },
};
</script>
<style lang="scss">
.load-full-screen {
  height: 100%;
  width: 100%;
}
#loader {
  // position: relative;
  background: white;
  //background: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  top: 0;
}
#loader .loading-text {
  position: absolute;
  top: 65%;
  left: 0;
  /* margin-top: -45px; */
  /* width: 250px; */
  text-align: center;
  /* margin-left: -80px; */
  color: #fff;
  font-size: 30px;
  right: 0;
  bottom: 0;
}
#loader span {
  text-align: center;
}
#flight-loader {
  position: relative;
  background: rgba(0, 0, 0, 0.3);
}
#flight-loader div {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -45%);
  margin-top: 0 !important;
}
#flight-loader span {
  text-align: center;
}
.unshow-loading {
  display: none;
}
.loading-animation {
  position: relative;
  height: 100px;
  margin-top: 40px;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  // transform: translate(-50%,-45%);
  // margin-top: 0 !important;
  margin-top: -45px;
}
.loading-animation span {
  border-radius: 50%;
  font-size: 50px;
  height: 70px;
  padding: 10px;
  width: 70px;
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -45px;
}
.loading-animation span:nth-child(1) {
  opacity: 0;
  -webkit-animation: loader 2s infinite;
  animation: loader 2s infinite;
  color: #fff;
  background: #f9676b;
}
.loading-animation span:nth-child(2) {
  -webkit-animation: loader 2s infinite 0.5s;
  animation: loader 2s infinite 0.5s;
  opacity: 0;
  color: #fff;
  background: #f19c4f;
}
.loading-animation span:nth-child(3) {
  animation: loader 2s infinite 1s;
  -webkit-animation: loader 2s infinite 1s;
  opacity: 0;
  color: #fff;
  background: #00adef;
}
.loading-animation span:nth-child(4) {
  animation: loader 2s infinite 1.5s;
  -webkit-animation: loader 2s infinite 1.5s;
  opacity: 0;
  color: #fff;
  background: #07253f;
}
@keyframes loader {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }
  25% {
    opacity: 1;
    -webkit-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
  26% {
    opacity: 0;
    -webkit-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }
}
@-webkit-keyframes loader {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }
  25% {
    opacity: 1;
    -webkit-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
  26% {
    opacity: 0;
    -webkit-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }
}
</style>
