import * as mutationName from "@/store/modules/insurance/mutationTypes"

const mutations = {
    [mutationName.UPDATE_QUOTE_INFO](state, payload){
        if (payload.quoteRS!=null && payload.quoteRS.planForQuoteRSList){
            state.quoteRS = payload.quoteRS;
            let basePremium = payload.quoteRS.planForQuoteRSList[0].planCost.basePremium;
            state.insuranceQuote.currencyCode = basePremium.currencyCode
            state.insuranceQuote.amount = basePremium.amount

        }else {
            state.quoteRS = {};
        }
    },

};

export default mutations;
