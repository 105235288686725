import state from "@/store/modules/cruise/state";
import getters from "@/store/modules/cruise/getters";
import actions from "@/store/modules/cruise/actions";
import mutations from "@/store/modules/cruise/mutations";

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
