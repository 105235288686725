import DateUtils from "@/utils/DateUtils";

function getAvailRQ(
  originCode,
  destCode,
  cabinPrefs,
  ttiCode,
  bestOnlyIndicator,
  checkInDate,
  checkOutDate,
  roomNum,
  adultNum,
  childNum,
  language,
  childAges
) {
  let hotelRef;
  if (ttiCode != null && ttiCode != "") {
    hotelRef = {};
    hotelRef.tticode = ttiCode;
    bestOnlyIndicator = false;
  } else {
    hotelRef = {
      hotelCityCode: destCode,
      hotelCodeContext: "AIRPORT",
    };
  }
  let roomStayCandidates = [];
  // childNum = 0;
  let childTotalNum = childAges.length;
  let adultTotalNum = adultNum * roomNum;
  if (childAges != null && childAges.length > 0) {
    // for (let i = 0; i < roomNum; i++) {
    //     let roomStayCandidate = {
    //         "quantity": 1,
    //         "guestCounts": {
    //             "guestCountList": [
    //                 {
    //                     "ageQualifyingCode": "10",
    //                     "count": adultNum
    //                 }
    //             ]
    //         }
    //     };
    //
    //     childAges.forEach(childAge => {
    //         if (childAge.roomId == i){
    //             let age = childAge.age;
    //             let childGuest = {
    //                 "ageQualifyingCode": "8",
    //                 "age": age,
    //                 "count": 1
    //             }
    //             roomStayCandidate.guestCounts.guestCountList.push(childGuest);
    //         }
    //
    //     })
    //     roomStayCandidates.push(roomStayCandidate)
    // }

    let roomStayCandidate = {
      quantity: roomNum,
      guestCounts: {
        guestCountList: [
          {
            ageQualifyingCode: "10",
            count: adultNum,
          },
        ],
      },
    };

    let childGuest = {
      ageQualifyingCode: "8",
      age: 8,
      count: childNum,
    };
    roomStayCandidate.guestCounts.guestCountList.push(childGuest);
    roomStayCandidates.push(roomStayCandidate);
  } else {
    childTotalNum = roomNum * childNum;
    roomStayCandidates = [
      {
        quantity: roomNum,
        guestCounts: {
          guestCountList: [
            {
              ageQualifyingCode: "10",
              count: adultNum,
            },
            {
              ageQualifyingCode: "8",
              age: 6,
              count: childNum,
            },
          ],
        },
      },
    ];
  }

  let passengerTypeQuantities = [
    {
      code: "ADT",
      quantity: adultTotalNum,
    },
  ];

  if (childTotalNum > 0) {
    passengerTypeQuantities.push({
      code: "CNN",
      quantity: childTotalNum,
    });
  }

  return {
    searchCriteria: {
      airSearch: [
        {
          originDestinationInformations: [
            {
              departureDateTime: checkInDate,
              originLocation: {
                locationCode: originCode,
              },
              destinationLocation: {
                locationCode: destCode,
              },
            },
          ],
          specificFlightInfo: {
            airline: {
              code: "YY",
            },
          },
          travelPreferences: {
            cabinPrefList: cabinPrefs,
          },
          travelRefSummary: {
            passengerTypeQuantities: {
              passengerTypeQuantities: passengerTypeQuantities,
            },
          },
        },
      ],
      hotelSearch: [
        {
          hotelSearchCriteria: {
            bestOnlyIndicator: bestOnlyIndicator,
            criterionList: [
              {
                hotelRefs: [hotelRef],
                stayDayRange: {
                  start: checkInDate,
                  end: checkOutDate,
                },
                roomStayCandidates: {
                  roomStayCandidates: roomStayCandidates,
                },
              },
            ],
          },
        },
      ],
    },
    pos: {
      sourceList: [
        {
          requestorID: {
            id: "admin",
          },
          bookingChannel: {
            companyName: {
              code: "",
            },
          },
        },
      ],
    },
  };
}

function getHotelsByAvailRS(availRS) {
  let result = {
    minPrice: null,
    maxPrice: null,
    currencyCode: "",
    hotels: [],
  };
  if (
    availRS == undefined ||
    (availRS.success != null && !availRS.success) ||
    availRS.roomStays == null ||
    availRS.roomStays.roomStays == null ||
    availRS.roomStays.roomStays.length == null
  ) {
    return [];
  } else if (availRS.success || availRS.roomStays.roomStays.length > 0) {
    let roomPriceMap = {};
    availRS.roomStays.roomStays.forEach((roomStay) => {
      let tticode = roomStay.basicPropertyInfo.TTIcode;
      let nights = roomStay.roomRates.roomRates[0].rates.rateList.length;
      if (roomPriceMap[tticode] != null) {
        let price = Math.ceil(roomStay.total.amountIncludingMarkup / nights);
        if (roomPriceMap[tticode].price > price) {
          roomPriceMap[tticode].price = price;
          roomPriceMap[tticode].totalPrice =
            roomStay.total.amountIncludingMarkup;
        }
      } else {
        roomPriceMap[tticode] = {
          price: Math.ceil(roomStay.total.amountIncludingMarkup / nights),
          totalPrice: roomStay.total.amountIncludingMarkup,
          currencyCode: roomStay.total.currencyCode,
        };
      }

      //
      let amountIncludingMarkup = Math.ceil(
        roomStay.total.amountIncludingMarkup / nights
      );
      result.currencyCode = roomStay.total.currencyCode;
      if (result.minPrice == null || result.minPrice > amountIncludingMarkup) {
        result.minPrice = amountIncludingMarkup;
      }
      if (result.maxPrice == null || result.maxPrice < amountIncludingMarkup) {
        result.maxPrice = amountIncludingMarkup;
      }
    });
    let roomStays = availRS.roomStays.roomStays;

    roomStays.forEach((roomStay) => {
      let hotel = getReconstructHotel(roomStay.basicPropertyInfo);
      //price
      hotel.price = roomPriceMap[hotel.ttiCode].price;
      hotel.currencyCode = roomPriceMap[hotel.ttiCode].currencyCode;

      result.hotels.push(hotel);
    });
  }
  return result;
}

/*开始 通用辅助函数*/
function getReconstructHotel(basicPropertyInfo) {
  let hotel = {
    ttiCode: "",
    name: "",
    nameZhCN: "",
    nameZhHK: "",
    imgUrl: "",
    star: 0,
    rating: 0,
    cityName: "",
    cityCode: "",
    country: "",
    address: "",
    longitude: 0,
    latitude: 0,
    price: 0,
    currencyCode: "",
    description: "",
  };

  //基本信息
  hotel.ttiCode = basicPropertyInfo.TTIcode;
  hotel.star =
    basicPropertyInfo.rating == null
      ? 0
      : Math.floor(parseFloat(basicPropertyInfo.rating));
  hotel.imgUrl = basicPropertyInfo.mainImageURL;
  if (basicPropertyInfo.awards != null && basicPropertyInfo.awards.length > 0) {
    basicPropertyInfo.awards.forEach((award) => {
      if (award.provider == "TRIPADVISOR") {
        hotel.rating =
          award.rating == null || award.rating == "" || award.rating == "null"
            ? 0
            : parseFloat(award.rating);
      }
    });
  }

  // 国际化
  hotel.name = basicPropertyInfo.hotelName;
  if (
    basicPropertyInfo.propertyNames != null &&
    basicPropertyInfo.propertyNames.length > 0
  ) {
    basicPropertyInfo.propertyNames.forEach((propertyName) => {
      let languageCode = propertyName.languageCode;
      switch (languageCode) {
        case "zh_HK":
          hotel.nameZhHK = propertyName.name;
          break;
        case "zh_CN":
          hotel.nameZhCN = propertyName.name;
          break;
        default:
      }
    });
  }

  // 地理位置
  if (basicPropertyInfo.position != null) {
    hotel.latitude = basicPropertyInfo.position.latitude;
    hotel.longitude = basicPropertyInfo.position.longitude;
  }

  // 城市与国家
  hotel.cityName = basicPropertyInfo.hotelCityName;
  hotel.cityCode = basicPropertyInfo.hotelCityCode;
  hotel.country = basicPropertyInfo.countryCode;
  if (basicPropertyInfo.address)
    hotel.address = basicPropertyInfo.address.addressString;

  // 文字描述
  hotel.description =
    basicPropertyInfo.shortDescriptions == null
      ? ""
      : basicPropertyInfo.shortDescriptions;

  return hotel;
}

function getHotelsShow(hotels, currentPage, pageSize) {
  let totalPages =
    hotels.length <= pageSize ? 1 : Math.ceil(hotels.length / pageSize);

  let starIndex = pageSize * (currentPage - 1);
  let endIndex =
    hotels.length <= pageSize || totalPages == currentPage
      ? hotels.length
      : pageSize * currentPage;
  return hotels.slice(starIndex, endIndex);
}

/*开始 酒店详情页相关*/
function getHotelSelected(hotelDetailsAvailRS, hotelDescriptionRS) {
  let hotelSelected = {};
  if (
    hotelDetailsAvailRS == undefined ||
    (hotelDetailsAvailRS.success != null && !hotelDetailsAvailRS.success) ||
    hotelDetailsAvailRS.roomStays == null ||
    hotelDetailsAvailRS.roomStays.roomStays == null ||
    hotelDetailsAvailRS.roomStays.roomStays.length == null
  ) {
    ////console.info('None result.')
  } else if (
    hotelDetailsAvailRS.success ||
    hotelDetailsAvailRS.roomStays.roomStays.length > 0
  ) {
    // 构建房间价格信息,以房型作为分类
    let roomTypes = {};
    hotelDetailsAvailRS.roomStays.roomStays.forEach((roomStay) => {
      if (Object.keys(hotelSelected) == 0) {
        hotelSelected = getReconstructHotel(roomStay.basicPropertyInfo);
      }
      // 房型相关
      let roomTypeNme = roomStay.roomTypes[0].roomType;
      let bedTypeName = roomStay.roomTypes[0].bedTypeName;
      let roomTypeCode = roomStay.roomTypes[0].roomTypeCode;
      let minOccupancy = roomStay.roomTypes[0].occupancies[0].minOccupancy;
      let maxOccupancy = roomStay.roomTypes[0].occupancies[0].maxOccupancy;
      let quantity = roomStay.roomTypes[0].quantity;
      if (roomTypes[roomTypeCode] == null) {
        roomTypes[roomTypeCode] = {
          typeCode: roomTypeCode,
          name: roomTypeNme,
          minOccupancy: minOccupancy,
          maxOccupancy: maxOccupancy,
          rooms: [],
        };
      }
      let room = {
        quantity: quantity,
        bedTypeName: bedTypeName,
      };

      // 餐饮相关
      let breakfast = roomStay.ratePlans[0].mealsIncluded.breakfast;
      let lunch = roomStay.ratePlans[0].mealsIncluded.lunch;
      let dinner = roomStay.ratePlans[0].mealsIncluded.dinner;
      let mealPlanIndicator =
        roomStay.ratePlans[0].mealsIncluded.mealPlanIndicator;
      room.breakfast = breakfast;
      room.lunch = lunch;
      room.dinner = dinner;
      room.mealPlanIndicator = mealPlanIndicator;

      //Rate Comments
      let roomRate = roomStay.roomRates.roomRates[0];
      let roomRateDescriptionList = roomRate.roomRateDescriptionList;
      let rateComments = null;
      if (roomRateDescriptionList) {
        roomRateDescriptionList.forEach((roomRateDescription) => {
          if ("RATE_COMMENTS" == roomRateDescription.name) {
            rateComments = roomRateDescription.textList[0].stringValue;
          }
        });
      }
      room.rateComments = rateComments;

      //Property Fees
      let taxes = roomRate.total.taxes;
      if (taxes && taxes.taxes) {
        room.propertyFees = [];
        taxes.taxes.forEach((tax) => {
          if (tax.type == "EXCLUSIVE") {
            let propertyFee = {
              amount: tax.amount,
              currencyCode: currencyCode,
              name: tax.taxDescriptions[0].name,
            };

            room.propertyFees.push(propertyFee);
          }
        });
      }

      //Tax and Fees inclusive
      let rates = roomRate.rates.rateList;
      let feesAndTaxes = 0;
      rates.forEach((rate) => {
        let fees = rate.fees;
        if (fees) {
          fees.forEach((fee) => {
            let amount = fee.amount;
            feesAndTaxes += amount;
          });
        }
      });
      room.nights = rates.length;
      room.amountPerNight = roomStay.total.amountIncludingMarkup / room.nights;
      room.feesAndTaxes = feesAndTaxes;
      //房价相关
      let totalAmount = roomStay.total.amountIncludingMarkup;
      let currencyCode = roomStay.total.currencyCode;
      let referenceId = roomStay.reference.id;
      room.totalAmount = totalAmount;
      room.unitAmount =
        Math.round((roomStay.total.amountIncludingMarkup / room.nights) * 100) /
        100;
      room.currencyCode = currencyCode;
      room.referenceId = referenceId;

      //住客相关
      room.adultNum = 0;
      room.childNum = 0;
      room.roomNum = 0;
      roomStay.guestCounts.guestCountList.forEach((guestCount) => {
        switch (guestCount.ageQualifyingCode) {
          case "10":
            room.adultNum += guestCount.count;
            break;
          case "8":
            room.childNum += guestCount.count;
            break;
          default:
        }
      });

      // 可取消日期
      let cancelPolicies = [];
      if (
        roomStay.cancelPenalties != null &&
        roomStay.cancelPenalties.cancelPenalties != null &&
        roomStay.cancelPenalties.cancelPenalties.length > 0
      ) {
        roomStay.cancelPenalties.cancelPenalties.forEach((cancelPenalty) => {
          if (cancelPenalty.deadline != null) {
            let absoluteDeadlineTimeStamp =
              cancelPenalty.deadline.absoluteDeadline;
            let absoluteDeadlineDate = new Date(absoluteDeadlineTimeStamp);
            let dateFormat = DateUtils.format(absoluteDeadlineDate);
            let amountPercent = cancelPenalty.amountPercent;

            let cancelPolicy = {
              date: dateFormat,
              amount: amountPercent.amount,
            };
            if (amountPercent.amount == null && amountPercent.percent != null) {
              cancelPolicy.amount =
                (amountPercent.percent / 100) * room.totalAmount;
            }

            cancelPolicies.push(cancelPolicy);
          }
        });
      }
      room.cancelPolicies = cancelPolicies;

      roomTypes[roomTypeCode].rooms.push(room);

      //basic info
      let vendorMessages = roomStay.basicPropertyInfo.vendorMessages;
      let policy = roomStay.basicPropertyInfo.policy;
      if (policy) {
        hotelSelected.checkInTime = policy.checkInTime;
        hotelSelected.checkOutTime = policy.checkOutTime;
      }
      if (vendorMessages) {
        let propertyContent = {};
        vendorMessages.forEach((vendorMessage) => {
          let infoType = vendorMessage.infoType;
          let paragraphs = vendorMessage.subSections[0].paragraphs;
          propertyContent[infoType] = paragraphs;
        });
        hotelSelected.propertyContent = propertyContent;
      }
    });
    hotelSelected.roomTypes = roomTypes;
  }

  if (
    hotelDescriptionRS == null ||
    hotelDescriptionRS.hotelDescriptiveContents == null ||
    hotelDescriptionRS.hotelDescriptiveContents.hotelDescriptiveContentList ==
      null ||
    hotelDescriptionRS.hotelDescriptiveContents.hotelDescriptiveContentList
      .length == 0
  ) {
    ////console.info('None');
  } else {
    let content =
      hotelDescriptionRS.hotelDescriptiveContents
        .hotelDescriptiveContentList[0];
    //服务设施
    hotelSelected.services = [];
    if (content.hotelInfo.serviceList != null) {
      hotelSelected.services = content.hotelInfo.serviceList;
    }

    //酒店名
    if (hotelSelected.name == null) {
      hotelSelected.name = content.hotelName;
    }

    // 酒店星级
    if (
      hotelSelected.star == null &&
      content.affiliationInfo != null &&
      content.affiliationInfo.awardList != null &&
      content.affiliationInfo.awardList.length > 0
    ) {
      hotelSelected.star = content.affiliationInfo.awardList[0].rating;
    }

    // 联系方式
    if (content.contactInfoList) {
      let contactInfo =
        content.contactInfoList && content.contactInfoList.length > 0
          ? content.contactInfoList[0]
          : null;
      if (contactInfo.emailList != null && contactInfo.emailList.length > 0) {
        hotelSelected.email = contactInfo.emailList[0].emailAddress;
      }

      if (contactInfo.urlList != null && contactInfo.urlList.length > 0) {
        hotelSelected.officialUrl = contactInfo.urlList[0].urlString;
      }

      if (contactInfo.phoneList != null && contactInfo.phoneList.length > 0) {
        contactInfo.phoneList.forEach((phone) => {
          if ("5" == phone.phoneUseType) {
            hotelSelected.contactNumer = phone.phoneNumber;
          } else {
            hotelSelected.contactNumer = phone.phoneNumber;
          }
        });
      }
    }

    // 图片，文字描述
    hotelSelected.images = [];
    if (
      content.multimediaDescriptions != null &&
      content.multimediaDescriptions.multimediaDescriptionList != null &&
      content.multimediaDescriptions.multimediaDescriptionList.length > 0
    ) {
      let multimediaDescription =
        content.multimediaDescriptions.multimediaDescriptionList[0];

      multimediaDescription.imageItems.forEach((imageItem) => {
        let image = {
          url: imageItem.url,
        };
        if (
          imageItem.imageFormatList != null &&
          imageItem.imageFormatList.length > 0 &&
          "bigger" == imageItem.imageFormatList[0].dimensionCategory
        ) {
          let roomTypeCode = imageItem.roomTypeCode;
          if (
            roomTypeCode != null &&
            hotelSelected != null &&
            hotelSelected.roomTypes != null &&
            hotelSelected.roomTypes[roomTypeCode] != null
          ) {
            hotelSelected.roomTypes[roomTypeCode].imgUrl = image.url;
          }
          hotelSelected.images.push(image);
        } else if (imageItem.imageFormatList == null) {
          hotelSelected.images.push(image);
        }
      });

      if (hotelSelected == null) hotelSelected = "";
      if (
        multimediaDescription.textItems &&
        multimediaDescription.textItems[0].descriptionList &&
        multimediaDescription.textItems[0].descriptionList[0].subSections &&
        multimediaDescription.textItems[0].descriptionList[0].subSections[0]
          .paragraphs
      ) {
        multimediaDescription.textItems[0].descriptionList[0].subSections[0].paragraphs.forEach(
          (paragraph) => {
            let text = paragraph.text;
            hotelSelected.description =
              hotelSelected.description + "<br>" + text;
          }
        );
      } else {
        hotelSelected.description =
          multimediaDescription.textItems[0].descriptionList[0].stringValue;
      }
    }
  }

  return hotelSelected;
}

/*结束 酒店详情页相关*/

/*开始 列表页相关*/
function getSpecificFlightItineraries(
  pricedItineraryInfos,
  groupNumber,
  origDestRph
) {
  let flightItinerariesResult = {
    transitOptions: [],
    airlineOptions: [],
    departureAirportOptions: [],
    arrivalAirportOptions: [],
    minFlyingTime: 0,
    maxFlyingTime: 0,
    minFlightPrice: 0,
    maxFlightPrice: 0,
    flightItineraries: [],
  };
  let flightsMap = {};
  pricedItineraryInfos.forEach((pricedItineraryInfo) => {
    if (groupNumber > 0) {
      let originDestinationOptionPre =
        pricedItineraryInfo.airItinerary.originDestinationOptions[
          groupNumber - 1
        ];
      // //console.info(originDestinationOptionPre)
      if (originDestinationOptionPre.rph == origDestRph) {
        flightsMap = getFlightMap(pricedItineraryInfo, groupNumber, flightsMap);
      }
    } else {
      flightsMap = getFlightMap(pricedItineraryInfo, groupNumber, flightsMap);
    }
  });

  let airlineMap = {};
  let departureAirportCodeMap = {};
  let arrivalAirportCodeMap = {};
  for (let key in flightsMap) {
    let flightShow = {
      price: null,
      currencyCode: "",
      originDestinationOptions: [],
    };
    let bundleKeyMap = {};
    flightsMap[key].forEach((originDestinationOption) => {
      let totalAmount = originDestinationOption.totalAmount;
      if (!flightShow.price || flightShow.price > totalAmount) {
        flightShow.price = totalAmount;
        flightShow.lowestBundleId = originDestinationOption.bundleID;
        flightShow.currencyCode = originDestinationOption.currencyCode;
        bundleKeyMap[originDestinationOption.bundleID] =
          originDestinationOption;

        //最高最低价格
        if (
          flightItinerariesResult.minFlightPrice == 0 ||
          flightItinerariesResult.minFlightPrice > flightShow.price
        ) {
          flightItinerariesResult.minFlightPrice = flightShow.price;
        }
        if (
          flightItinerariesResult.maxFlightPrice == 0 ||
          flightItinerariesResult.maxFlightPrice < totalAmount
        ) {
          flightItinerariesResult.maxFlightPrice = totalAmount;
        }
      }
      if (!bundleKeyMap[originDestinationOption.bundleID]) {
        bundleKeyMap[originDestinationOption.bundleID] =
          originDestinationOption;
        // originDestinationOptionMap[originDestinationOption.bundleID+":"+totalAmount] = originDestinationOption.bundleID;
      }
      // 防止没有bundleID，但是，价格不一样的情况
      // else if (!originDestinationOptionMap[originDestinationOption.bundleID+":"+totalAmount]){
      //     bundleKeyMap[originDestinationOption.bundleID+":"+totalAmount] = originDestinationOption;
      //     originDestinationOptionMap[originDestinationOption.bundleID+":"+totalAmount] = originDestinationOption.bundleID;
      // }

      //最短最长时长
      let durationInMins = originDestinationOption.durationInMins;
      if (
        flightItinerariesResult.minFlyingTime == 0 ||
        flightItinerariesResult.minFlyingTime > durationInMins
      ) {
        flightItinerariesResult.minFlyingTime = durationInMins;
      }
      if (
        flightItinerariesResult.maxFlyingTime == 0 ||
        flightItinerariesResult.maxFlyingTime < durationInMins
      ) {
        flightItinerariesResult.maxFlyingTime = durationInMins;
      }

      //机场
      departureAirportCodeMap[originDestinationOption.originalDepartureCode] =
        originDestinationOption.originalDepartureCode;
      arrivalAirportCodeMap[originDestinationOption.destinationArrivalCode] =
        originDestinationOption.destinationArrivalCode;

      //航空公司
      originDestinationOption.flightSegments.forEach((flightSegment) => {
        airlineMap[flightSegment.operatingAirlineCode] =
          flightSegment.operatingAirlineCode;
      });
    });
    for (let bundleKey in bundleKeyMap) {
      flightShow.originDestinationOptions.push(bundleKeyMap[bundleKey]);
    }

    flightItinerariesResult.flightItineraries.push(flightShow);
  }

  for (let key in airlineMap) {
    flightItinerariesResult.airlineOptions.push(airlineMap[key]);
  }
  for (let key in departureAirportCodeMap) {
    flightItinerariesResult.departureAirportOptions.push(
      departureAirportCodeMap[key]
    );
  }
  for (let key in arrivalAirportCodeMap) {
    flightItinerariesResult.arrivalAirportOptions.push(
      arrivalAirportCodeMap[key]
    );
  }
  //console.info(flightItinerariesResult);
  return flightItinerariesResult;
}

function getFlightMap(pricedItineraryInfo, groupNumber, flightsMap) {
  let originDestinationOption =
    pricedItineraryInfo.airItinerary.originDestinationOptions[groupNumber];

  if (!flightsMap[originDestinationOption.rph]) {
    flightsMap[originDestinationOption.rph] = [];
  }

  //
  let passengerFareList =
    pricedItineraryInfo.airItineraryPricingInfo.PTC_FareBreakdowns[0]
      .passengerFareList;
  let totalAmount =
    pricedItineraryInfo.airItineraryPricingInfo.itinTotalFares[0].totalFare
      .amount;
  let currencyCode = "";
  pricedItineraryInfo.airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList.forEach(
    (passengerFare) => {
      if (passengerFare.usage == "TicketFee") {
        currencyCode = passengerFare.baseFare.currencyCode;
      }
    }
  );

  originDestinationOption.totalAmount = totalAmount;
  originDestinationOption.currencyCode = currencyCode;
  originDestinationOption.passengerFareList = passengerFareList;
  originDestinationOption.priceType = pricedItineraryInfo.priceType;
  flightsMap[originDestinationOption.rph].push(originDestinationOption);

  return flightsMap;
}

function getItinerariesShow(flightItineraries, currentPage, pageSize) {
  let totalPages =
    flightItineraries.length <= pageSize
      ? 1
      : Math.ceil(flightItineraries.length / pageSize);

  let starIndex = pageSize * (currentPage - 1);
  let endIndex =
    flightItineraries.length <= pageSize || totalPages == currentPage
      ? flightItineraries.length
      : pageSize * currentPage;
  return flightItineraries.slice(starIndex, endIndex);
}

/*结束 请求相关*/

/*开始 详情页相关*/
function getVerifyPriceRQ(airItinerary, passengerTypeQuantityList) {
  return {
    airItinerary: airItinerary,
    travelerInfoSummary: {
      airTravelerAvailList: [
        {
          passengerTypeQuantityList: passengerTypeQuantityList,
        },
      ],
      priceRequestInformation: {
        negotiatedFareCodeList: [
          {
            supplierCode: "OPENJAWNDC-CATHAYPACIFIC",
          },
        ],
      },
    },
  };
}

function getAirRuleRQ(rph) {
  return {
    abbreviatedRuleTextInd: false,
    ruleReqInfo: {
      // this rph is from airprice RS
      rph: rph,
    },
  };
}

/*结束 详情页相关*/

/*开始 Offer相关*/
function getAirGetOfferRQ(qutoId) {
  return {
    requestCriterion: {
      airItinerary: {
        itineraries: [
          {
            priced: false,
            rph: qutoId,
          },
        ],
      },
    },
  };
}

function getPriceWithOfferRQ(priceIds, itineraryRph) {
  return {
    travelerInfoSummary: {
      priceRequestInformation: {},
    },
    offer: {
      priced: priceIds,
      summary: [
        {
          itineraryRPH: itineraryRph,
        },
      ],
    },
  };
}

/*结束 Offer相关*/

/*开始 预定页相关*/
function getAirBookRQ(
  quoteID,
  travelerInfos,
  seatRequests,
  pricedOffers,
  username
) {
  let airTravelerList = [];
  let i = 0;
  travelerInfos.forEach((travelerInfo) => {
    if (travelerInfo.passengerTypeCode == "ADT") {
      let airPassenger = {
        travelerRefNumber: {
          rph: "PAX" + ++i,
        },
        passengerTypeCode: travelerInfo.passengerTypeCode,
        personName: {
          namePrefix: travelerInfo.title,
          givenName: travelerInfo.firstName,
          surname: travelerInfo.lastName,
          nameTitle: travelerInfo.title,
        },
        documents: [
          {
            docID: travelerInfo.passportNumber,
            expireDate: travelerInfo.expireDate,
            docIssueCountry: travelerInfo.passportCountryCode,
            docHolderNationality: travelerInfo.passportCountryCode,
          },
        ],
        birthDate: travelerInfo.birth,
        emailList: [
          {
            emailAddress: travelerInfo.emailAddress,
          },
        ],
        telephoneList: [
          {
            countryAccessCode: travelerInfo.areaCode,
            phoneNumber: travelerInfo.phoneNumber,
          },
        ],
      };
      airTravelerList.push(airPassenger);
    } else {
      let airPassenger = {
        travelerRefNumber: {
          rph: "PAX" + ++i,
        },
        passengerTypeCode: travelerInfo.passengerTypeCode,
        personName: {
          namePrefix: travelerInfo.title,
          givenName: travelerInfo.firstName,
          surname: travelerInfo.lastName,
          nameTitle: travelerInfo.title,
        },
        documents: [
          {
            docID: travelerInfo.passportNumber,
            expireDate: travelerInfo.expireDate,
            docIssueCountry: travelerInfo.passportCountryCode,
            docHolderNationality: travelerInfo.passportCountryCode,
          },
        ],
        birthDate: travelerInfo.birth,
        telephoneList: [
          {
            countryAccessCode: travelerInfo.areaCode,
            phoneNumber: travelerInfo.phoneNumber,
          },
        ],
      };
      airTravelerList.push(airPassenger);
    }
  });

  return {
    targetName: "PROD",
    airItinerary: {
      airItineraryRPH: quoteID,
    },
    priceInfo: {
      quoteID: quoteID,
    },
    travelerInfo: {
      airTravelerList: airTravelerList,
      specialReqDetails: {
        seatRequests: seatRequests,
      },
    },
    offer: {
      priced: pricedOffers,
    },
    pos: {
      sourceList: [
        {
          bookingChannel: {
            companyName: {
              code: "",
            },
            type: "7",
          },
          requestorID: {
            id: username,
          },
        },
      ],
    },
  };
}

function getPackageResRQ(
  airItinerary,
  roomStays,
  checkInDate,
  checkOutDate,
  passengerInfos,
  username,
  status,
  quoteId,
  seatRequests,
  pricedOffers
) {
  let roomStayList = [];
  let resGuestList = [];
  let resGuestInfoList = [];
  let roomIndex = 1;
  let guestRPH = 0;
  roomStays.forEach((roomStay) => {
    roomStay.roomTypes[0].quantity = 1;
    roomStay.guestCounts.guestCountList = [];
    passengerInfos.forEach((passengerInfo) => {
      if (passengerInfo.roomNum == roomIndex) {
        let ageQualifyingCode = "10";
        if (passengerInfo.passengerTypeCode != "ADT") {
          ageQualifyingCode = "8";
        }
        roomStay.guestCounts.guestCountList.push({
          resGuestRPH: guestRPH,
          ageQualifyingCode: ageQualifyingCode,
          age: passengerInfo.age,
          count: 1,
        });

        let resGuest = {
          // "countryAccessCode": passengerInfo.,
          passportNumber: passengerInfo.passportNumber,
          expireDate: passengerInfo.expireDate,
          emailAddress: passengerInfo.emailAddress,
          phoneNumber: passengerInfo.phoneNumber,
          surname: passengerInfo.lastName,
          nameTitle: passengerInfo.title,
          givenName: passengerInfo.firstName,
          ageQualifyingCode: ageQualifyingCode,
          birthDate: passengerInfo.birth,
          resGuestRPH: guestRPH,
          age: passengerInfo.age,
          docIssueCountry: passengerInfo.passportCountryCode,
        };

        let gender = "Male";
        let title = passengerInfo.title;
        if (title == "Mrs" || title == "Ms" || title == "Miss") {
          gender = "Female";
        }

        let guestInfo = {
          resGuestRPH: guestRPH,
          ageQualifyingCode: ageQualifyingCode,
          age: passengerInfo.age,
          profiles: {
            profileInfos: [
              {
                profileList: [
                  {
                    creatorID: username,
                    customer: {
                      gender: gender,
                      birthDate: passengerInfo.birth,
                      personName: {
                        namePrefix: passengerInfo.title,
                        givenName: passengerInfo.firstName,
                        surnamePrefix: passengerInfo.title,
                        surname: passengerInfo.lastName,
                        nameTitle: passengerInfo.title,
                      },
                      telephoneList: [
                        {
                          phoneNumber: passengerInfo.phoneNumber,
                        },
                      ],
                      documentList: [
                        {
                          docID: passengerInfo.passportNumber,
                          expireDate: passengerInfo.expireDate,
                          docIssueCountry: passengerInfo.passportCountryCode,
                          birthCountry: passengerInfo.passportCountryCode,
                          docHolderNationality:
                            passengerInfo.passportCountryCode,
                        },
                      ],
                      emailList: [
                        {
                          emailAddress: passengerInfo.emailAddress,
                          stringValue: passengerInfo.emailAddress,
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          },
        };
        resGuestInfoList.push(guestInfo);
        resGuestList.push(resGuest);
        guestRPH += 1;
      }
    });
    roomStayList.push(roomStay);
    roomIndex += 1;
  });

  let hotelStatus = "hold";
  if (status.toLowerCase() == "initiate") {
    hotelStatus = "Initiate";
    airItinerary.airItineraryRPH = quoteId;
  }
  return {
    targetName: "PROD",
    dynamicPackage: {
      dynamicPkgAction: status,
      components: {
        hotelComponents: [
          {
            dynamicPkgAction: hotelStatus,
            resStatus: hotelStatus,
            billingInstructionCode: {
              accountNumber: username,
            },
            resGlobalInfo: {
              timeSpan: {
                start: checkInDate,
                end: checkOutDate,
              },
            },
            roomStayList: roomStayList,
            pos: {
              sourceList: [
                {
                  bookingChannel: {
                    companyName: {
                      code: "",
                    },
                    type: "6",
                  },
                  requestorID: {
                    id: username,
                  },
                },
              ],
            },
            resGuestList: resGuestList,
          },
        ],
        airComponents: [
          {
            quoteID: quoteId,
            airItinerary: airItinerary,
            travelerInfo: {
              specialReqDetails: {
                seatRequests: seatRequests,
              },
            },
            offer: {
              priced: pricedOffers,
            },
          },
        ],
      },
      resGuests: resGuestInfoList,
    },
    pos: {
      sourceList: [
        {
          requestorID: {
            id: username,
          },
          bookingChannel: {
            companyName: {
              code: "",
            },
          },
        },
      ],
    },
  };
}

function getPackageBookRQ(packagePreBookRQ, hotelReservation, airReservation) {
  let packageBookRQ = JSON.parse(JSON.stringify(packagePreBookRQ));
  let hotelComponent =
    packageBookRQ.dynamicPackage.components.hotelComponents[0];
  hotelComponent.roomStayList = hotelReservation.roomStayList;
  hotelComponent.uniqueIDList = hotelReservation.uniqueIDList;

  let airComponent = packageBookRQ.dynamicPackage.components.airComponents[0];
  airComponent.airItinerary = airReservation.airItinerary;
  airComponent.quoteID = airComponent.airItinerary.airItineraryRPH;

  return packageBookRQ;
}

/*结束 预定页相关*/

/*开始 通用相关*/
function getWrapFlightSegment(flightSegment) {
  let flightItinerary = flightSegment;
  if (flightSegment.segmentDetailsList) {
    flightItinerary.segmentDetails = flightSegment.segmentDetailsList[0];
  } else {
    flightItinerary.segmentDetails = {};
    let arrivalDateTime = flightSegment.arrivalDateTime;
    let departureDateTime = flightSegment.departureDateTime;
    let minutes =
      (new Date(arrivalDateTime).getTime() -
        new Date(departureDateTime).getTime()) /
      1000 /
      60;
    flightItinerary.segmentDetails.flightTime = minutes;
  }

  let hours =
    flightItinerary.segmentDetails.flightTime / 60 == 0
      ? ""
      : Math.floor(flightItinerary.segmentDetails.flightTime / 60) + "h";
  let munitues = (flightItinerary.segmentDetails.flightTime % 60) + "m";
  flightSegment.duration = hours + munitues;
  flightSegment.departureTime = DateUtils.formatDate(
    new Date(flightSegment.departureDateTime),
    "HH:mm"
  );
  flightSegment.arrivalTime = DateUtils.formatDate(
    new Date(flightSegment.arrivalDateTime),
    "HH:mm"
  );
  return flightSegment;
}

function getWrapOriginDestinationOption(originDestinationOption) {
  let durationInMins = originDestinationOption.durationInMins;
  let hours =
    durationInMins / 60 == 0 ? "" : Math.floor(durationInMins / 60) + "h";
  let munitues = (durationInMins % 60) + "m";
  originDestinationOption.duration = hours + munitues;

  let groupNumber = null;
  let flightSegmentsNew = [];
  originDestinationOption.flightSegments.forEach((flightSegment) => {
    let flightSegmentNew = getWrapFlightSegment(flightSegment);
    groupNumber = flightSegmentNew.groupNumber;
    flightSegmentsNew.push(flightSegmentNew);
    // originDestinationOptionInfo.itineraryInfos.push(flightItinerary);
    if (!originDestinationOption.originalDepartureCode) {
      originDestinationOption.originalDepartureCode =
        flightSegment.departureAirportCode;
      let date = new Date(flightSegment.departureDateTime);
      let hourInDay = date.getHours();
      let timeInterval =
        hourInDay >= 5 && hourInDay < 12
          ? "MORNING"
          : hourInDay >= 12 && hourInDay < 18
          ? "AFTERNOON"
          : "EVENING";

      originDestinationOption.originalDepartureDateTime = DateUtils.formatDate(
        date,
        "YYYY-MM-DD HH:mm"
      );
      originDestinationOption.departureTimeInterval = timeInterval;
    }
    originDestinationOption.destinationArrivalCode =
      flightSegment.arrivalAirportCode;
    let date = new Date(flightSegment.arrivalDateTime);
    let hourInDay = date.getHours();
    let timeInterval =
      hourInDay >= 5 && hourInDay < 12
        ? "MORNING"
        : hourInDay >= 12 && hourInDay < 18
        ? "AFTERNOON"
        : "EVENING";
    originDestinationOption.destinationArrivalDateTime = DateUtils.formatDate(
      new Date(flightSegment.arrivalDateTime),
      "YYYY-MM-DD HH:mm"
    );
    originDestinationOption.arrivalTimeInteraval = timeInterval;
  });

  if (originDestinationOption.flightSegments.length > 1) {
    let length = originDestinationOption.flightSegments.length;
    let flightSegments = originDestinationOption.flightSegments;
    originDestinationOption.flightNumbers =
      flightSegments[0].operatingAirlineCode +
      "-" +
      flightSegments[0].flightNumber +
      ",";
    originDestinationOption.stopCodes = "";
    originDestinationOption.stopNumber = length - 1;
    for (let i = 1; i < length; i++) {
      originDestinationOption.stopCodes +=
        flightSegments[i].departureAirportCode;
      originDestinationOption.flightNumbers +=
        flightSegments[i].operatingAirlineCode +
        "-" +
        flightSegments[i].flightNumber;
      if (i < length - 1) {
        originDestinationOption.stopCodes += ",";
        originDestinationOption.flightNumbers += ",";
      }
    }
  } else {
    originDestinationOption.stopNumber = 0;
  }
  originDestinationOption.flightSegments = flightSegmentsNew;
  originDestinationOption.groupNumber = groupNumber;
  return originDestinationOption;
}

function getWrapperPricedItineraryList(pricedItineraryList) {
  pricedItineraryList.forEach((pricedItinerary) => {
    let wrapOriginDestinationOptions = [];
    pricedItinerary.airItinerary.originDestinationOptions.forEach(
      (originDestinationOption) => {
        let wrapOriginDestinationOption = getWrapOriginDestinationOption(
          originDestinationOption
        );

        let match = false;
        if (pricedItinerary.offer && pricedItinerary.offer.summary) {
          pricedItinerary.offer.summary.forEach((summary) => {
            if (summary.origDestRPH == wrapOriginDestinationOption.rph) {
              wrapOriginDestinationOption.bundleID = summary.bundleID;
              match = true;
            }
          });
        }

        if (!match) {
          wrapOriginDestinationOption.bundleID = "-1";
        }

        wrapOriginDestinationOptions.push(wrapOriginDestinationOption);
      }
    );
    pricedItinerary.airItinerary.originDestinationOptions =
      wrapOriginDestinationOptions;
  });

  return pricedItineraryList;
}

function getWrapperAirItinerary(airItinerary) {
  let wrapperAirItinerary = {
    originDestinationOptions: [],
  };
  airItinerary.originDestinationOptions.forEach((originDestinationOption) => {
    let wrapOriginDestinationOption = getWrapOriginDestinationOption(
      originDestinationOption
    );

    wrapperAirItinerary.originDestinationOptions.push(
      wrapOriginDestinationOption
    );
  });
  return wrapperAirItinerary;
}

function getOfferMap(offerSummary) {
  let offerMap = {};

  offerSummary.forEach((summary) => {
    if (!summary.bundleID) summary.bundleID = "-1";
    offerMap[summary.bundleID] = summary;
  });

  return offerMap;
}

export default {
  getAvailRQ,
  getHotelsByAvailRS,
  getHotelsShow,
  getHotelSelected,
  getPackageResRQ,
  getPackageBookRQ,
  getVerifyPriceRQ,
  getAirGetOfferRQ,
  getAirRuleRQ,
  getPriceWithOfferRQ,
  getSpecificFlightItineraries,
  getItinerariesShow,
  getOfferMap,
  getAirBookRQ,
  getWrapperPricedItineraryList,
  getWrapperAirItinerary,
};
