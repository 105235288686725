const state={
    lowFareSearchRS: {},
    pricedItineraryInfos: [],
    offerMap: {},
    originDestinationShow:{},
    originDestinationMap: {},
    airlineOptions:[],
    departureAirportOptions:[],
    arrivalAirportOptions:[],
    minFlyingTime: 0,
    maxFlyingTime: 0,
    minFlightPrice: 0,
    maxFlightPrice: 0,
    currencyCode: '',
    flightItineraries: [],
    flightItinerariesShow: [],
    flightResultNum: 0,
    groupNumberShow: 0,
    verifyPricedItineraryInfo: {},
    fareRuleMap: {},
    offersRS: {},
    offers: [],
    passengerNumMap:{},
    airBookRS: {},
    totalPayment: {
        currencyCode: '',
        amount: 0
    }
};

export default state;
