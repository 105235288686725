import * as mutationName from "@/store/modules/package/mutationTypes";
import DynamicPkgUtils from "@/utils/DynamicPkgUtils";
import DateUtils from "@/utils/DateUtils";
import SortUtils from "@/utils/SortUtils";
import FilterUtils from "@/utils/FilterUtils";

const mutations = {
  [mutationName.UPDATE_CITY_HOTELS_AVAIL](state, payload) {
    if (
      payload.packageCityAvailRS != null &&
      payload.packageCityAvailRS.searchResults &&
      payload.packageCityAvailRS.searchResults.hotelResults &&
      payload.packageCityAvailRS.searchResults.hotelResults.length > 0 &&
      payload.packageCityAvailRS.searchResults.airResults &&
      payload.packageCityAvailRS.searchResults.airResults.length > 0
    ) {
      state.cityHotelsAvailRS =
        payload.packageCityAvailRS.searchResults.hotelResults[0];
      let result = DynamicPkgUtils.getHotelsByAvailRS(state.cityHotelsAvailRS);
      state.hotels = result.hotels;
      state.resultNum = result.hotels.length;
      state.minHotelPrice = result.minPrice;
      state.maxHotelPrice = result.maxPrice;
      state.currencyCode = result.currencyCode;
      state.hotelsShow = [];
      //console.info(state.hotels)
      let hotelsShow = DynamicPkgUtils.getHotelsShow(
        state.hotels,
        1,
        payload.pageSize
      );
      state.hotelsShow = hotelsShow;
    } else {
      state.cityHotelsAvailRS = {};
      state.hotels = [];
    }
  },
  [mutationName.UPDATE_HOTELS_SHOW](state, payload) {
    let currentPage = payload.currentPage;
    let pageSize = payload.pageSize;
    let filterCondition = payload.filterCondition;
    let hotels = state.hotels;
    hotels = FilterUtils.filterHotels(hotels, filterCondition);

    if (
      payload.sortCondition.sortAttribute != null &&
      payload.sortCondition.type != null
    ) {
      hotels = SortUtils.sort(
        hotels,
        payload.sortCondition.sortAttribute,
        payload.sortCondition.type
      );
    }
    state.resultNum = hotels == null ? 0 : hotels.length;

    state.hotelsShow = [];
    let hotelsShow = DynamicPkgUtils.getHotelsShow(
      hotels,
      currentPage,
      pageSize
    );
    state.hotelsShow = hotelsShow;
  },
  [mutationName.UPDATE_HOTEL_DETAILS_AVAIL](state, payload) {
    if (
      payload.packageHotelDetailsAvailRS != null &&
      payload.packageHotelDetailsAvailRS.searchResults &&
      payload.packageHotelDetailsAvailRS.searchResults.hotelResults &&
      payload.packageHotelDetailsAvailRS.searchResults.hotelResults.length >
        0 &&
      payload.packageHotelDetailsAvailRS.searchResults.airResults &&
      payload.packageHotelDetailsAvailRS.searchResults.airResults.length > 0
    ) {
      state.hotelDetailsAvailRS =
        payload.packageHotelDetailsAvailRS.searchResults.hotelResults[0];
      let hotelSelected = DynamicPkgUtils.getHotelSelected(
        state.hotelDetailsAvailRS,
        state.hotelDescriptionRS
      );
      state.hotelSelected = hotelSelected;
    } else {
      state.hotelDetailsAvailRS = {};
      state.hotelSelected = {};
    }

    if (
      payload.packageHotelDetailsAvailRS != null &&
      payload.packageHotelDetailsAvailRS.searchResults &&
      payload.packageHotelDetailsAvailRS.searchResults.airResults &&
      payload.packageHotelDetailsAvailRS.searchResults.airResults.length > 0
    ) {
      state.lowFareSearchRS =
        payload.packageHotelDetailsAvailRS.searchResults.airResults[0];
      sessionStorage.setItem(
        "packageAirLowFareSearchRS",
        JSON.stringify(state.lowFareSearchRS)
      );
      let pricedItineraryList = DynamicPkgUtils.getWrapperPricedItineraryList(
        state.lowFareSearchRS.pricedItineraries.pricedItineraries
      );
      if (state.lowFareSearchRS.offer && state.lowFareSearchRS.offer) {
        let offerMap = DynamicPkgUtils.getOfferMap(
          state.lowFareSearchRS.offer.summary
        );
        state.offerMap = offerMap;
      } else {
        state.offerMap = {};
      }
      let wrapperPricedItineraryList = pricedItineraryList;
      if (wrapperPricedItineraryList) {
        let originDestinationOptions =
          wrapperPricedItineraryList[0].airItinerary.originDestinationOptions;
        let originDestinationMap = {};
        for (let i = 0; i < originDestinationOptions.length; i++) {
          originDestinationMap[i] = {
            departureCode: originDestinationOptions[i].originalDepartureCode,
            arrivalCode: originDestinationOptions[i].destinationArrivalCode,
            departureDate:
              originDestinationOptions[i].destinationArrivalDateTime.split(
                " "
              )[0],
          };
        }
        state.originDestinationMap = originDestinationMap;
        //console.info(state.originDestinationMap);
        state.originDestinationShow = state.originDestinationMap[0];
        state.groupNumberShow = 0;
      } else {
        state.originDestinationMap = {};
        state.originDestinationShow = {};
        state.groupNumberShow = 0;
      }
      state.pricedItineraryInfos = wrapperPricedItineraryList;
      let flightItinerariesResult =
        DynamicPkgUtils.getSpecificFlightItineraries(
          state.pricedItineraryInfos,
          0
        );
      state.flightItineraries = flightItinerariesResult.flightItineraries;
      state.flightResultNum =
        state.flightItineraries == null ? 0 : state.flightItineraries.length;

      state.airlineOptions = flightItinerariesResult.airlineOptions;
      state.departureAirportOptions =
        flightItinerariesResult.departureAirportOptions;
      state.arrivalAirportOptions =
        flightItinerariesResult.arrivalAirportOptions;
      state.tripNum = "0";
      state.minFlyingTime = flightItinerariesResult.minFlyingTime;
      state.maxFlyingTime = flightItinerariesResult.maxFlyingTime;
      state.minFlightPrice = flightItinerariesResult.minFlightPrice;
      state.maxFlightPrice = flightItinerariesResult.maxFlightPrice;
      state.currencyCode =
        flightItinerariesResult.flightItineraries[0].currencyCode;
      //分页
      state.flightItinerariesShow = DynamicPkgUtils.getItinerariesShow(
        flightItinerariesResult.flightItineraries,
        1,
        10
      );
    } else {
      state.flightItineraries = [];
      state.flightItinerariesShow = [];
    }
  },
  [mutationName.UPDATE_HOTEL_DESCRIPTION](state, payload) {
    if (payload.hotelDescriptionRS != null) {
      state.hotelDescriptionRS = payload.hotelDescriptionRS;
    } else {
      state.hotelDescriptionRS = {};
    }

    let hotelSelected = DynamicPkgUtils.getHotelSelected(
      state.hotelDetailsAvailRS,
      state.hotelDescriptionRS
    );
    state.hotelSelected = hotelSelected;
  },
  [mutationName.UPDATE_CONFIRM](state, payload) {
    if (payload.confirmRS != null) {
      state.confirmRS = payload.confirmRS;
      state.preBookRQ = payload.preBookRQ;
      let packageBookingInfo = {
        basicInfo: {
          hotelName: "",
          roomType: "",
          bedTypeName: "",
          checkIn: "",
          checkOut: "",
          roomNum: 0,
          guestPerRoom: "",
          durationDays: 0,
        },
        passengerInfos: [],
        priceDetails: {
          pricePerRoom: 0,
          hotelTotalPrice: 0,
          airTotalPrice: 0,
          propertyFees: [],
          feesAndTaxesPerRoom: 0,
          total: 0,
        },
      };
      if (state.confirmRS.hotelReservationList) {
        let hotelReservation = state.confirmRS.hotelReservationList[0];
        let resGlobalInfo = hotelReservation.resGlobalInfo;
        let timeSpan = resGlobalInfo.timeSpan;

        let total = resGlobalInfo.total;
        packageBookingInfo.priceDetails.total = total.amountIncludingMarkup;
        packageBookingInfo.priceDetails.currencyCode = total.currencyCode;
        let resGuestList = hotelReservation.resGuestList;
        let resGuestMap = {};
        resGuestList.forEach((resGuest) => {
          let customer =
            resGuest.profiles.profileInfos[0].profileList[0].customer;
          let personName = customer.personName;
          resGuest.surname = personName.surname;
          resGuest.givenName = personName.givenName;
          resGuest.nameTitle = personName.nameTitle;
          resGuest.birthDate = customer.birthDate;

          let telephone = customer.telephoneList[0];
          let emailEle = customer.emailList[0];
          resGuest.phoneNumber = telephone.phoneNumber;
          resGuest.emailAddress = emailEle.stringValue;

          resGuestMap[resGuest.resGuestRPH] = resGuest;
        });

        let roomStayList = hotelReservation.roomStayList;
        let guestPerRoom = "";
        let rateComments = "";
        roomStayList.forEach((roomStay) => {
          let passengerInfo = {
            adults: [],
            childern: [],
          };
          roomStay.guestCounts.guestCountList.forEach((guestCount) => {
            if (guestCount.ageQualifyingCode == "10") {
              passengerInfo.adults.push(resGuestMap[guestCount.resGuestRPH]);
            } else if (guestCount.ageQualifyingCode == "8") {
              passengerInfo.childern.push(resGuestMap[guestCount.resGuestRPH]);
            }
          });

          guestPerRoom = passengerInfo.adults.length + "adult(s)";
          if (passengerInfo.childern.length > 0) {
            guestPerRoom +=
              ", " + passengerInfo.childern.length + " child(ern)";
          }

          if (
            roomStay.specialRequests != null &&
            roomStay.specialRequests.specialRequests != null &&
            roomStay.specialRequests.specialRequests.length > 0
          ) {
            let specialRequest =
              roomStay.specialRequests.specialRequests[0].text;
            passengerInfo.remarks = specialRequest;
          }

          roomStay.roomRates.roomRates.forEach((roomRate) => {
            if (roomRate.roomRateDescriptionList) {
              roomRate.roomRateDescriptionList.forEach((rateDescription) => {
                if (rateDescription.name == "RATE_COMMENTS") {
                  passengerInfo.rateComments =
                    rateDescription.textList[0].stringValue;
                }
              });
            }

            //Property Fees
            if (
              roomRate.total &&
              roomRate.total.taxes &&
              roomRate.total.taxes.taxes
            ) {
              let taxes = roomRate.total.taxes;
              packageBookingInfo.priceDetails.propertyFees = [];
              taxes.taxes.forEach((tax) => {
                if (tax.type == "EXCLUSIVE") {
                  let propertyFee = {
                    amount: tax.amount,
                    currencyCode: total.currencyCode,
                    name: tax.taxDescriptions[0].name,
                  };

                  packageBookingInfo.priceDetails.propertyFees.push(
                    propertyFee
                  );
                }
              });
            }

            //Tax and Fees inclusive
            let rates = roomRate.rates.rateList;
            let feesAndTaxes = 0;
            rates.forEach((rate) => {
              let fees = rate.fees;
              if (fees) {
                fees.forEach((fee) => {
                  let amount = fee.amount;
                  feesAndTaxes += amount;
                });
              }
            });
            packageBookingInfo.priceDetails.feesAndTaxesPerRoom = feesAndTaxes;
          });

          //basic info
          let vendorMessages = roomStay.basicPropertyInfo.vendorMessages;

          if (vendorMessages) {
            let propertyContent = {};
            vendorMessages.forEach((vendorMessage) => {
              let infoType = vendorMessage.infoType;
              let paragraphs = vendorMessage.subSections[0].paragraphs;
              propertyContent[infoType] = paragraphs;
            });
            packageBookingInfo.basicInfo.propertyContent = propertyContent;
          }

          packageBookingInfo.passengerInfos.push(passengerInfo);

          packageBookingInfo.priceDetails.hotelTotalPrice +=
            roomStay.total.amountIncludingMarkup;
        });

        //基础信息
        let durationDays = DateUtils.getDayDurationByDates(
          timeSpan.start,
          timeSpan.end
        );
        let basicPropertyInfo = roomStayList[0].basicPropertyInfo;
        let roomType = roomStayList[0].roomTypes[0];
        let roomTotal = roomStayList[0].total;
        packageBookingInfo.priceDetails.pricePerRoom =
          Math.round(
            ((roomTotal.amountIncludingMarkup -
              packageBookingInfo.priceDetails.feesAndTaxesPerRoom) /
              durationDays) *
              100
          ) / 100;
        packageBookingInfo.priceDetails.rateComments = rateComments;
        packageBookingInfo.priceDetails.currencyCode = roomTotal.currencyCode;

        packageBookingInfo.basicInfo.hotelName = basicPropertyInfo.hotelName;
        packageBookingInfo.basicInfo.roomType = roomType.roomType;
        packageBookingInfo.basicInfo.bedTypeName = roomType.bedTypeName;
        packageBookingInfo.basicInfo.checkIn = DateUtils.format(
          new Date(timeSpan.start)
        );
        packageBookingInfo.basicInfo.checkOut = DateUtils.format(
          new Date(timeSpan.end)
        );
        packageBookingInfo.basicInfo.roomNum = roomStayList.length;
        packageBookingInfo.basicInfo.guestPerRoom = guestPerRoom;
        packageBookingInfo.basicInfo.durationDays = durationDays;
      }

      if (state.confirmRS.pricedItineraryList) {
        state.confirmRS.pricedItineraryList.forEach((pricedItinerary) => {
          let airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
          if (airItineraryPricingInfo.itinTotalFares) {
            airItineraryPricingInfo.itinTotalFares.forEach((itinTotalFare) => {
              if ("TicketFee" == itinTotalFare.usage) {
                packageBookingInfo.priceDetails.airTotalPrice +=
                  itinTotalFare.totalFare.amount;
              }
            });
          } else {
            airItineraryPricingInfo.PTC_FareBreakdowns.forEach((breakDown) => {
              let quantity = breakDown.passengerTypeQuantity.quantity;
              breakDown.passengerFareList.forEach((passengerFare) => {
                if ("TicketFee" == passengerFare.usage) {
                  packageBookingInfo.priceDetails.airTotalPrice +=
                    passengerFare.totalFare.amount * quantity;
                }
              });
            });
          }
        });
      }
      packageBookingInfo.priceDetails.total =
        packageBookingInfo.priceDetails.hotelTotalPrice +
        packageBookingInfo.priceDetails.airTotalPrice;

      //console.info(packageBookingInfo)
      state.packageBookingInfo = packageBookingInfo;
    } else {
      state.confirmRS = {};
    }
  },
  [mutationName.RESET_STATE](state, payload) {
    state[payload.stateName] = payload.resetValue;
  },
  [mutationName.UPDATE_PREBOOK](state, payload) {
    if (payload.hotelReservation != null) {
      state.hotelReservation = payload.hotelReservation;
      let total = state.hotelReservation.resGlobalInfo.total;
      let totalPayment = {
        currencyCode: total.currencyCode,
        amount: total.amountIncludingMarkup,
      };
      state.hotelTotalPayment = totalPayment;
    } else {
      state.hotelReservation = null;
      state.hotelTotalPayment = 0;
    }

    if (payload.airReservation) {
      state.airReservation = payload.airReservation;

      let ptcFareBreakdowns =
        state.airReservation.airItineraryPricingInfo.PTC_FareBreakdowns;
      let totalAmount = 0;
      let currency = "";
      ptcFareBreakdowns.forEach((ptcFareBreakdown) => {
        ptcFareBreakdown.passengerFareList.forEach((passengerFare) => {
          if ("TicketFee" == passengerFare.usage) {
            let totalFare = passengerFare.totalFare;
            currency = totalFare.currencyCode;
            totalAmount += totalFare.amount;
          }
        });
      });
      state.flightTotalPayment = {
        currencyCode: currency,
        amount: totalAmount,
      };
    } else {
      state.airReservation = null;
      state.flightTotalPayment = 0;
    }

    state.packageTotalPayment = {
      currencyCode: state.flightTotalPayment.currencyCode,
      amount: state.flightTotalPayment.amount + state.hotelTotalPayment.amount,
    };

    if (
      payload.packagePreBookRQ &&
      payload.airReservation &&
      payload.hotelReservation
    ) {
      let packagePreBookRQ = payload.packagePreBookRQ;
      let airReservation = payload.airReservation;
      let hotelReservation = payload.hotelReservation;

      let packageBookRQ = DynamicPkgUtils.getPackageBookRQ(
        packagePreBookRQ,
        hotelReservation,
        airReservation
      );
      state.packageBookRQ = packageBookRQ;
    } else {
      state.packagePreBookRQ = null;
    }
  },
  /*机票*/
  [mutationName.UPDATE_FLIGHTS_INFO](state, payload) {
    if (
      payload.packageLowFareSearchRS &&
      payload.packageLowFareSearchRS.pricedItineraries &&
      payload.packageLowFareSearchRS.pricedItineraries.pricedItineraries
    ) {
      state.lowFareSearchRS = payload.packageLowFareSearchRS;
      let pricedItineraryList = DynamicPkgUtils.getWrapperPricedItineraryList(
        state.lowFareSearchRS.pricedItineraries.pricedItineraries
      );
      if (state.lowFareSearchRS.offer && state.lowFareSearchRS.offer) {
        let offerMap = DynamicPkgUtils.getOfferMap(
          state.lowFareSearchRS.offer.summary
        );
        state.offerMap = offerMap;
      } else {
        state.offerMap = {};
      }
      let wrapperPricedItineraryList = pricedItineraryList;
      if (wrapperPricedItineraryList) {
        let originDestinationOptions =
          wrapperPricedItineraryList[0].airItinerary.originDestinationOptions;
        let originDestinationMap = {};
        for (let i = 0; i < originDestinationOptions.length; i++) {
          originDestinationMap[i] = {
            departureCode: originDestinationOptions[i].originalDepartureCode,
            arrivalCode: originDestinationOptions[i].destinationArrivalCode,
            departureDate:
              originDestinationOptions[i].destinationArrivalDateTime.split(
                " "
              )[0],
          };
        }
        state.originDestinationMap = originDestinationMap;
        //console.info(state.originDestinationMap);
        state.originDestinationShow = state.originDestinationMap[0];
        state.groupNumberShow = 0;
      } else {
        state.originDestinationMap = {};
        state.originDestinationShow = {};
        state.groupNumberShow = 0;
      }
      state.pricedItineraryInfos = wrapperPricedItineraryList;
      let flightItinerariesResult =
        DynamicPkgUtils.getSpecificFlightItineraries(
          state.pricedItineraryInfos,
          0
        );
      state.flightItineraries = flightItinerariesResult.flightItineraries;
      state.flightResultNum =
        state.flightItineraries == null ? 0 : state.flightItineraries.length;

      state.airlineOptions = flightItinerariesResult.airlineOptions;
      state.departureAirportOptions =
        flightItinerariesResult.departureAirportOptions;
      state.arrivalAirportOptions =
        flightItinerariesResult.arrivalAirportOptions;
      state.tripNum = "0";
      state.minFlyingTime = flightItinerariesResult.minFlyingTime;
      state.maxFlyingTime = flightItinerariesResult.maxFlyingTime;
      state.minFlightPrice = flightItinerariesResult.minFlightPrice;
      state.maxFlightPrice = flightItinerariesResult.maxFlightPrice;
      state.currencyCode =
        flightItinerariesResult.flightItineraries[0].currencyCode;
      //分页
      state.flightItinerariesShow = DynamicPkgUtils.getItinerariesShow(
        flightItinerariesResult.flightItineraries,
        1,
        10
      );
    } else {
      state.flightItineraries = [];
      state.flightItinerariesShow = [];
    }
  },
  [mutationName.UPDATE_FLIGHTS_SHOW](state, payload) {
    let currentPage = payload.currentPage;
    let pageSize = payload.pageSize;
    let filterCondition = payload.filterCondition;
    let flightItineraries = state.flightItineraries;
    flightItineraries = FilterUtils.filterFlights(
      flightItineraries,
      filterCondition
    );

    if (
      payload.sortCondition.sortAttribute != null &&
      payload.sortCondition.type != null
    ) {
      flightItineraries = SortUtils.sort(
        flightItineraries,
        payload.sortCondition.sortAttribute,
        payload.sortCondition.type
      );
    }
    state.flightResultNum =
      flightItineraries == null ? 0 : flightItineraries.length;

    state.flightItinerariesShow = [];
    let flightItinerariesShow = DynamicPkgUtils.getItinerariesShow(
      flightItineraries,
      currentPage,
      pageSize
    );
    state.flightItinerariesShow = flightItinerariesShow;
  },
  [mutationName.VERIFY_PRICE_FLIGHT_ITINERARY](state, payload) {
    if (payload.verifyPriceRS) {
      state.verifyPriceRS = payload.verifyPriceRS;
      let pricedItineraryList = DynamicPkgUtils.getWrapperPricedItineraryList(
        payload.verifyPriceRS.pricedItineraryList
      );
      if (
        payload.verifyPriceRS.offer &&
        payload.verifyPriceRS.offer &&
        Object.keys(payload.verifyPriceRS.offer).length > 0
      ) {
        let offerMap = DynamicPkgUtils.getOfferMap(
          payload.verifyPriceRS.offer.summary
        );
        state.offerMap = offerMap;
      } else {
        state.offerMap = {};
      }
      let wrapperPricedItineraryList = pricedItineraryList;
      //console.info(wrapperPricedItineraryList);
      state.verifyPricedItineraryInfo = wrapperPricedItineraryList[0];
      //console.info('Set')

      let ptcFareBreakdowns =
        state.verifyPricedItineraryInfo.airItineraryPricingInfo
          .PTC_FareBreakdowns;

      let passengerNumber = 1;
      let passengerNumMap = {};
      ptcFareBreakdowns.forEach((fareBreakdown) => {
        let code = fareBreakdown.passengerTypeQuantity.code;
        //passenger index
        let quantity = fareBreakdown.passengerTypeQuantity.quantity;
        for (
          let passengerIndex = 1;
          passengerIndex <= quantity;
          passengerIndex++
        ) {
          passengerNumMap[code + ":" + passengerIndex] = passengerNumber;
          ++passengerNumber;
        }
      });
      state.passengerNumMap = passengerNumMap;
    } else {
      state.verifyPriceRS = {};
    }
  },
  [mutationName.UPDATE_FLIGHT_RULES](state, payload) {
    if (payload.fareRulesRS && payload.fareRulesRS.success) {
      state.fareRulesRS = payload.fareRulesRS;
      let fareRuleMap = {};
      payload.fareRulesRS.fareRuleResponseInfos.forEach((fareRule) => {
        let fareRuleInfo = fareRule.fareRuleInfo;
        let departureAirportCode = fareRuleInfo.departureAirportCode;
        let arrivalAirportCode = fareRuleInfo.arrivalAirportCode;
        let key = departureAirportCode + " - " + arrivalAirportCode;
        let subSections = [];
        fareRule.fareRules.subSections.forEach((subSection) => {
          subSection.paragraphs.forEach((paragraph) => {
            let text = paragraph.text.replaceAll("\n", "<br>");
            paragraph.text = text;
          });
          subSections.push(subSection);
        });
        fareRuleMap[key] = subSections;
      });
      //console.info(fareRuleMap);
      state.fareRuleMap = fareRuleMap;
    } else {
      state.fareRulesRS = {};
    }
  },
  [mutationName.UPDATE_FLIGHT_OFFERS_SEATMAP](state, payload) {
    if (payload.offersRS && payload.offersRS.pricedOffers) {
      state.offersRS = payload.offersRS;
      let offers = [];
      payload.offersRS.pricedOffers.forEach((pricedOffer) => {
        let name = pricedOffer.name;
        let amount = pricedOffer.pricing.amount;
        let pricingCurrency = pricedOffer.pricing.pricingCurrency;
        let flightSegmentRPH = pricedOffer.flightSegmentRPH;
        let travelerRPH = pricedOffer.travelerRPH;
        let id = pricedOffer.id;

        let offer = {
          name,
          amount,
          pricingCurrency,
          flightSegmentRPH,
          travelerRPH,
          id,
        };
        if (
          pricedOffer.serviceFamily &&
          pricedOffer.serviceFamily.productGroups
        ) {
          let productGroup = pricedOffer.serviceFamily.productGroups[0];
          let productCode = productGroup.code;
          let subGroupCode = productGroup.subGroups[0].code;
          offer.productCode = productCode;
          offer.subGroupCode = subGroupCode;
        }
        offers.push(offer);
      });
      //console.info(offers);
      state.offers = offers;
      state.seatMaps = payload.offersRS.seatMaps;
    } else {
      state.offersRS = {};
      state.offers = [];
    }
  },
};

export default mutations;
