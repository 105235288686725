const getters = {
  hotels(state) {
    return state.hotels;
  },
  hotelsShow(state) {
    return state.hotelsShow;
  },
  hotelResultNum(state) {
    return state.resultNum;
  },
  maxHotelPrice(state) {
    return state.maxHotelPrice;
  },
  minHotelPrice(state) {
    return state.minHotelPrice;
  },
  currencyCode(state) {
    return state.currencyCode;
  },
  //酒店详情相关
  hotelSelected(state) {
    // ////console.info(state.hotelSelected);
    return state.hotelSelected;
  },
  roomStaySelected: (state) => (referenceId) => {
    return state.hotelDetailsAvailRS.roomStays.roomStays.find(
      (roomStay) => roomStay.reference.id === referenceId
    );
  },
  packageBookingInfo(state) {
    return state.packageBookingInfo;
  },
  preBookRQ(state) {
    return state.preBookRQ;
  },
  totalPayment(state) {
    return state.totalPayment;
  },
  preBookRS(state) {
    return state.preBookRS;
  },
  uniqueIDs(state) {
    if (
      state.preBookRS &&
      state.preBookRS.hotelReservationList &&
      state.preBookRS.hotelReservationList.length > 0 &&
      state.preBookRS.hotelReservationList[0]
    ) {
      return state.preBookRS.hotelReservationList[0].uniqueIDList;
    }
  },
  bookRQ(state) {
    let preBookRQ = JSON.parse(JSON.stringify(state.preBookRQ));
    let preBookRS = state.preBookRS;

    preBookRQ.resStatus = "Book";
    if (
      preBookRQ &&
      preBookRQ.hotelReservationList &&
      preBookRQ.hotelReservationList.length > 0 &&
      preBookRQ.hotelReservationList[0] &&
      preBookRS &&
      preBookRS.hotelReservationList &&
      preBookRS.hotelReservationList.length > 0 &&
      preBookRS.hotelReservationList[0]
    ) {
      preBookRQ.hotelReservationList[0].resStatus = "Book";
      preBookRQ.hotelReservationList[0].uniqueIDList =
        preBookRS.hotelReservationList[0].uniqueIDList;
      preBookRQ.hotelReservationList[0].roomStayList =
        preBookRS.hotelReservationList[0].roomStayList;
    }

    let bookRQ = preBookRQ;
    return bookRQ;
  },
  /* 机票*/
  flightItineraries(state) {
    return state.flightItineraries;
  },
  offerMap(state) {
    return state.offerMap;
  },
  originDestinationMap(state) {
    return state.originDestinationMap;
  },
  originDestinationShow(state) {
    return state.originDestinationShow;
  },
  airlineOptions(state) {
    return state.airlineOptions;
  },
  departureAirportOptions(state) {
    return state.departureAirportOptions;
  },
  arrivalAirportOptions(state) {
    return state.arrivalAirportOptions;
  },
  passengerNumMap(state) {
    return state.passengerNumMap;
  },
  pricedItineraries(state) {
    return state.lowFareSearchRS.pricedItineraries.pricedItineraries;
  },
  verifyPricedItineraryInfo(state) {
    return state.verifyPricedItineraryInfo;
  },
  fareRuleMap(state) {
    return state.fareRuleMap;
  },
  offers(state) {
    return state.offers;
  },
  seatMaps(state) {
    return state.seatMaps;
  },
  packageTotalPayment(state) {
    return state.packageTotalPayment;
  },
};

export default getters;
