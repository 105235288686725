function getSailAvailRQ(sailAvailFormModel) {
  let sailingDateRange = null;
  if (sailAvailFormModel.startDate) {
    sailingDateRange = {
      start: sailAvailFormModel.startDate,
      end: sailAvailFormModel.endDate,
      minDuration: sailAvailFormModel.minDuration,
      maxDuration: sailAvailFormModel.maxDuration,
    };
  }
  let cruiseLinePrefs = null;
  if (sailAvailFormModel.shipCode || sailAvailFormModel.portCode) {
    cruiseLinePrefs = [];
    let cruiseLinePref = {
      shipCode: sailAvailFormModel.shipCode,
    };

    if (sailAvailFormModel.portCode) {
      cruiseLinePref.searchQualifiers = {
        port: { portCode: sailAvailFormModel.portCode },
      };
    }
    cruiseLinePrefs.push(cruiseLinePref);
  }

  let regionPrefs = null;
  if (sailAvailFormModel.regionCode) {
    regionPrefs = [
      {
        regionCode: sailAvailFormModel.regionCode,
      },
    ];
  }

  return {
    guestCounts: [
      {
        quantity: 1,
      },
    ],
    sailingDateRange: sailingDateRange,
    cruiseLinePrefs: cruiseLinePrefs,
    regionPrefs: regionPrefs,
  };
}

/*
 *  获取航程安排描述的请求对象
 * @param shipCode string 必填，游轮代码，由SailAvail步骤响应中获取，例如，FR
 * @param startDate string 必填，游轮启航日期，格式为yyyy-MM-dd，由SailAvail步骤响应中获取，例如，2023-09-18
 * @param cruisePackageCode string 必填，游轮活动指定唯一标识，由SailAvail步骤响应中获取，例如，FR4BH159
 */
function getItineraryDescRQ(shipCode, startDate, cruisePackageCode) {
  return {
    selectedSailing: {
      shipCode: shipCode,
      start: startDate,
    },
    packageOption: {
      cruisePackageCode: cruisePackageCode,
    },
  };
}

/*
 *  获取指定日期游轮各舱型价格
 * @param shipCode string 必填，游轮代码，由SailAvail步骤响应中获取，例如，FR
 * @param startDate string 必填，游轮启航日期，格式为yyyy-MM-dd，由SailAvail步骤响应中获取，例如，2023-09-18
 * @param guestNum number 必填，乘客数量，默认1个人，最多4人
 */
function getCategoryListRQ(shipCode, startDate, guestNum, roomRum) {
  if (!guestNum) guestNum = 1;
  let guests = [];
  let guestCounts = [];
  for (let i = 0; i < guestNum; i++) {
    guests.push({
      guestTransportations: [
        {
          mode: 14,
          status: 36,
          gatewayCity: {
            locationCode: "C/O",
            codeContext: "IATA",
          },
        },
      ],
    });

    guestCounts.push({
      quantity: 1,
    });
  }

  return {
    maxResponses: 40,
    guests: guests,
    guestCounts: guestCounts,
    sailingInfo: {
      selectedSailing: {
        cruiseLine: {
          shipCode: shipCode,
        },
        start: startDate,
      },
    },
  };
}

/*
 *  获取对应位置类型的可预定房间
 * @param shipCode string 必填，游轮代码，由SailAvail步骤响应中获取，例如，FR
 * @param startDate string 必填，游轮启航日期，格式为yyyy-MM-dd'T'HH:mm:ss，由SailAvail步骤响应中获取，例如，2023-09-18T00:00:00
 * @param guestNum number 必填，乘客数量，默认1个人，最多4人
 * @param categoryCode string 必填，房间类型，由CategoryList步骤响应中获取，例如，RS
 * @param fareCode string 必填，票价代码，唯一标识，由CategoryList步骤响应中获取，例如，J1426691
 */
function getCabinAvailRQ(
  shipCode,
  startDate,
  guestNum,
  categoryCode,
  fareCode,
  roomRum
) {
  if (!guestNum) guestNum = 1;
  let guests = [];
  let guestCounts = [];
  for (let i = 0; i < guestNum; i++) {
    guests.push({
      guestTransportations: [
        {
          mode: 14,
          status: 36,
          gatewayCity: {
            locationCode: "C/O",
            codeContext: "IATA",
          },
        },
      ],
    });

    guestCounts.push({
      quantity: 1,
    });
  }

  return {
    guests: guests,
    guestCounts: guestCounts,
    sailingInfo: {
      selectedSailing: {
        cruiseLine: {
          shipCode: shipCode,
        },
        start: startDate,
      },
    },
    searchQualifiers: {
      berthedCategoryCode: categoryCode,
      fareCode: fareCode,
    },
  };
}

/*
 *  获取指定房间号的详细描述
 * @param shipCode string 必填，游轮代码，由SailAvail步骤响应中获取，例如，FR
 * @param startDate string 必填，游轮启航日期，格式为yyyy-MM-dd'T'HH:mm:ss，由SailAvail步骤响应中获取，例如，2023-09-18T00:00:00
 * @param cabinNumber string 必填，房间号，由CabinAvail步骤响应中获取，例如8020
 */
function getCabinDetailRQ(shipCode, startDate, cabinNumber) {
  return {
    sailingInfo: {
      selectedSailing: {
        cruiseLine: {
          shipCode: shipCode,
        },
        start: startDate,
      },
      selectedCategories: [
        {
          selectedCabins: [
            {
              cabinNumber: cabinNumber,
            },
          ],
        },
      ],
    },
  };
}

/*
 *  获取船上的用餐安排
 * @param shipCode string 必填，游轮代码，由SailAvail步骤响应中获取，例如，FR
 * @param startDate string 必填，游轮启航日期，格式为yyyy-MM-dd'T'HH:mm:ss，由SailAvail步骤响应中获取，例如，2023-09-18T00:00:00
 * @param guestNum number 必填，乘客数量，默认1个人，最多4人
 */
function getDiningAvailRQ(shipCode, startDate, guestNum, roomRum) {
  if (!guestNum) guestNum = 1;

  let guestCounts = [];
  for (let i = 0; i < guestNum; i++) {
    guestCounts.push({
      quantity: 1,
    });
  }

  return {
    guestCounts: guestCounts,
    sailingInfo: {
      selectedSailing: {
        cruiseLine: {
          shipCode: shipCode,
        },
        start: startDate,
      },
    },
  };
}

/*
 * 锁定房间。最多只能锁定四间相同房型的房间，锁定15分钟。
 * @param shipCode string 必填，游轮代码，由SailAvail步骤响应中获取，例如，FR
 * @param startDate string 必填，游轮启航日期，格式为yyyy-MM-dd，由SailAvail步骤响应中获取，截取日期，例如，2023-09-18
 * @param guestNum number 必填，乘客数量，默认1个人，最多4人
 * @param categoryCode string 必填，房间类型，由CategoryList步骤响应中获取，例如，RS
 * @param fareCode string 必填，票价代码，唯一标识，由CategoryList步骤响应中获取，例如，J1426691
 * @param cruisePackageCode string 必填，游轮活动指定唯一标识，由SailAvail步骤响应中获取，例如，FR4BH159
 * @param cabinNumbers string数组 必填，房间号数组，由房号CabinAvail步骤响应中获取，例如，例如["8020"]
 */
function getCabinHoldRQ(
  shipCode,
  startDate,
  guestNum,
  categoryCode,
  fareCode,
  cruisePackageCode,
  cabinNumbers
) {
  if (!guestNum) guestNum = 1;

  let guestCounts = [];
  for (let i = 0; i < guestNum; i++) {
    guestCounts.push({
      quantity: 1,
    });
  }
  let selectedCabins = [];
  cabinNumbers.forEach((cabinNumber) => {
    selectedCabins.push({
      cabinNumber: cabinNumber,
    });
  });

  return {
    guestCounts: guestCounts,
    selectedSailings: [
      {
        start: startDate,
        shipCode: shipCode,
        selectedFare: {
          fareCode: fareCode,
        },
        selectedCategories: [
          {
            pricedCategoryCode: categoryCode,
            selectedCabins: selectedCabins,
          },
        ],
        inclusivePackageOption: {
          cruisePackageCode: cruisePackageCode,
        },
      },
    ],
    searchQualifiers: null,
  };
}

/*
 * 游轮订单报价。RoomGuestInfo中，guestInfo可以为空对象数组，指示人数多少，不需要详情
 * @param shipCode string 必填，游轮代码，由SailAvail步骤响应中获取，例如，FR
 * @param startDate string 必填，游轮启航日期，格式为yyyy-MM-dd'T'HH:mm:ss，由SailAvail步骤响应中获取，例如，2023-09-18T00:00:00
 * @param categoryCode string 必填，房间类型，由CategoryList步骤响应中获取，例如，RS
 * @param fareCode string 必填，票价代码，唯一标识，由CategoryList步骤响应中获取，例如，J1426691
 * @param cruisePackageCode string 必填，游轮活动指定唯一标识，由SailAvail步骤响应中获取，例如，FR4BH159
 * @param cabinNumber string 必填，房间号，由CabinAvail步骤响应中获取，例如8020
 * @param diningSitting string 必填，用餐配置代码，由DiningAvail步骤响应中获取，例如O
 * @param roomGuestInfos Array 必填，代表房间对象的住客信息数组，一个元素代表一个房间，一个房间有1至4位住客，是RoomGuestInfo数组
 */

/*
let roomGuestInfos =[
{
  diningSitting: "M",
  guestInfos:[
    {
    ageCode: "10",//07 for child, 10 for Adult
    givenName: "";
    surname: "";
    citizenship: "";
    nameTitle: "";
    phoneNumber: "";
    emailAddress: "";
    }
  ]
 }
]
* */
function getPriceBookingRQ(
  shipCode,
  startDate,
  categoryCode,
  fareCode,
  cruisePackageCode,
  cabinNumber,
  roomGuestInfos
) {
  return getResRQ(
    shipCode,
    startDate,
    categoryCode,
    fareCode,
    cruisePackageCode,
    cabinNumber,
    roomGuestInfos
  );
}

/*
 * 最终预定。RoomGuestInfo中，guestInfo需要详情，包括主要住客的信息，例如，名字
 * @param shipCode string 必填，游轮代码，由SailAvail步骤响应中获取，例如，FR
 * @param startDate string 必填，游轮启航日期，格式为yyyy-MM-dd'T'HH:mm:ss，由SailAvail步骤响应中获取，例如，2023-09-18T00:00:00
 * @param categoryCode string 必填，房间类型，由CategoryList步骤响应中获取，例如，RS
 * @param fareCode string 必填，票价代码，唯一标识，由CategoryList步骤响应中获取，例如，J1426691
 * @param cruisePackageCode string 必填，游轮活动指定唯一标识，由SailAvail步骤响应中获取，例如，FR4BH159
 * @param cabinNumber string 必填，房间号，由CabinAvail步骤响应中获取，例如8020
 * @param diningSitting string 必填，用餐配置代码，由DiningAvail步骤响应中获取，例如O
 * @param roomGuestInfos Array 必填，代表房间对象的住客信息数组，一个元素代表一个房间，一个房间有1至4位住客，是RoomGuestInfo数组
 */
function getBookRQ(
  shipCode,
  startDate,
  categoryCode,
  fareCode,
  cruisePackageCode,
  cabinNumber,
  roomGuestInfos
) {
  return getResRQ(
    shipCode,
    startDate,
    categoryCode,
    fareCode,
    cruisePackageCode,
    cabinNumber,
    roomGuestInfos
  );
}

function getResRQ(
  shipCode,
  startDate,
  categoryCode,
  fareCode,
  cruisePackageCode,
  cabinNumber,
  roomGuestInfos,
  duration,
  contract
) {
  let guestDetails = [];
  roomGuestInfos.forEach((roomGuestInfo) => {
    roomGuestInfo.guestInfos.forEach((guestInfo) => {
      let contactInfos = [];
      if (guestInfo && (guestInfo.phoneNumber || guestInfo.givenName)) {
        contactInfos.push({
          contactType: "CNT",
          nationality: guestInfo.citizenship,
          telephoneList: [
            {
              phoneNumber: guestInfo.phoneNumber,
            },
          ],
          emailList: [
            {
              emailList: [
                {
                  emailType: "1",
                  stringValue: guestInfo.emailAddress,
                },
              ],
            },
          ],
          personName: {
            givenName: guestInfo.givenName,
            surname: guestInfo.surname,
            nameTitle: guestInfo.nameTitle,
          },
        });
      }

      guestDetails.push({
        contactInfos: contactInfos,
        guestTransportations: [
          {
            mode: "29",
            status: "36",
            gatewayCity: {
              locationCode: "C/O",
            },
          },
        ],
        selectedDinings: [
          {
            sitting: roomGuestInfo.diningSitting,
          },
        ],
      });
    });
  });

  let agentInfo = {
    contact: "JEBSEN",
  };
  return {
    agentInfo: agentInfo,
    sailingInfo: {
      selectedSailing: {
        cruiseLine: {
          shipCode: shipCode,
        },
        start: startDate,
      },
      selectedCategories: [
        {
          berthedCategoryCode: categoryCode,
          fareCode: fareCode,
          pricedCategoryCode: categoryCode,
          selectedCabins: [
            {
              status: "48",
              cabinNumber: cabinNumber,
            },
          ],
        },
      ],
      inclusivePackageOption: {
        cruisePackageCode: cruisePackageCode,
      },
    },
    reservationInfo: {
      guestDetails: guestDetails,
    },
  };
}

//表单搜索对象
class SailAvailFormModel {
  /*
   * startDate  String 出发时间起始范围，2023-09-18
   * endDate  String optional 出发时间终止范围，2023-09-18
   * minDuration  String 最小航行天数，6天，格式为P6N,
   * maxDuration  String optional 最大航行天数，8天，格式为P8N，
   * shipCode String optional 船代码，可为空，
   * region String optional 船出发地点，destination code，可为空，
   * portCode String optional 上船港口，可为空
   * */
  constructor(
    startDate,
    endDate,
    minDuration,
    maxDuration,
    duration,
    shipCode,
    portCode,
    regionCode,
    dates
  ) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.dates = dates;
    this.duration = duration;
    this.minDuration = minDuration;
    this.maxDuration = maxDuration;
    this.shipCode = shipCode;
    this.portCode = portCode;
    this.regionCode = regionCode;
  }
}

class RoomGuestInfo {
  constructor(diningSitting, guestInfos) {
    this.diningSitting = diningSitting;
    this.guestInfos = guestInfos;
  }
}

class GuestInfo {
  /*
   * @param phoneNumber string，必填，格式为305-982-1111，
   * */
  constructor(
    givenName,
    surname,
    birthDate,
    citizenship,
    nameTitle,
    phoneNumber,
    emailAddress
  ) {
    this.givenName = givenName;
    this.surname = surname;
    this.citizenship = citizenship;
    this.birthDate = birthDate;
    this.nameTitle = nameTitle;
    this.phoneNumber = phoneNumber;
    this.emailAddress = emailAddress;
  }
}

//region 构造视图对象
function getCruiseViewItems(availCruiseRS) {
  let availCruiseRSItems = [];
  availCruiseRS.forEach((availCruiseRSItem) => {
    const cruisePackageCode = availCruiseRSItem.inclusivePackageOption
      ? availCruiseRSItem.inclusivePackageOption.cruisePackageCode
      : null;

    const selectedSailing = availCruiseRSItem.selectedSailing;
    const arrivalPort = selectedSailing.arrivalPort;
    const arrivalLocationCode = arrivalPort.locationCode;
    const arrivalLocationName = arrivalPort.locationName;
    const cruiseLine = selectedSailing.cruiseLine;
    const vendorCode = cruiseLine.vendorCode;
    const vendorName = cruiseLine.vendorName;
    const shipCode = cruiseLine.shipCode;
    const shipName = cruiseLine.shipName ? cruiseLine.shipName : null;

    const departurePort = selectedSailing.departurePort;
    const departureLocationCode = departurePort.locationCode;
    const departureLocationName = departurePort.locationName;
    const duration = selectedSailing.duration;
    const portsOfCallQuantity = selectedSailing.portsOfCallQuantity;
    const region = selectedSailing.region;
    const regionCode = region.regionCode;
    const regionName = region.regionName;
    const subRegionCode = region.subRegionCode;
    const startDate = selectedSailing.start;

    const cruiseViewItem = new CruiseViewItem(
      arrivalLocationCode,
      arrivalLocationName,
      vendorCode,
      vendorName,
      shipCode,
      shipName,
      departureLocationCode,
      departureLocationName,
      duration,
      portsOfCallQuantity,
      regionCode,
      regionName,
      subRegionCode,
      startDate,
      cruisePackageCode
    );

    availCruiseRSItems.push(cruiseViewItem);
  });
  return availCruiseRSItems;
}

function getCategoryLocationViewItems(categoryOptions) {
  let categoryFareList = [];
  let categoryFareLocationMap = {};
  categoryFareLocationMap = categoryOptions.reduce((acc, currentObj) => {
    const key = currentObj.categoryLocation;
    if (!acc[key]) {
      acc[key] = [];
    }
    const cabinCategoryName = currentObj.cabinCategoryName;
    const pricedCategoryCode = currentObj.pricedCategoryCode;
    const priceInfos = currentObj.priceInfos;
    const cabinCategoryViewItem = new CabinCategoryViewItem(
      cabinCategoryName,
      pricedCategoryCode,
      priceInfos
    );
    acc[key].push(cabinCategoryViewItem);
    return acc;
  }, {});

  for (const locationKey in categoryFareLocationMap) {
    let categoryLocationViewItem = new CategoryLocationViewItem(
      locationKey,
      categoryFareLocationMap[locationKey]
    );
    categoryFareList.push(categoryLocationViewItem);
  }

  return categoryFareList;
}

function getRoomInfoList(cabinOptions) {
  let roomInfoList = [];
  if (cabinOptions && cabinOptions.length > 0) {
    cabinOptions.forEach((cabinOption) => {
      const position = cabinOption.positionInShip;
      const cabinNumber = cabinOption.cabinNumber;
      const deckNumber = cabinOption.deckNumber ? cabinOption.deckNumber : null;
      const deckName = cabinOption.deckName ? cabinOption.deckName : null;
      const cabinCategoryCode = cabinOption.cabinCategoryCode;
      const measurementInfos = cabinOption.measurementInfos;
      const configurations = cabinOption.cabinConfigurations;
      const equipmentDescription = cabinOption.measurementInfos;

      const roomInfoListItem = new RoomInfoListItem(
        equipmentDescription,
        configurations,
        measurementInfos,
        cabinCategoryCode,
        cabinNumber,
        deckName,
        deckNumber,
        position
      );
      roomInfoList.push(roomInfoListItem);
    });
  }
  return roomInfoList;
}

class RoomInfoListItem {
  constructor(
    equipmentDescription,
    configurations,
    measurementInfos,
    cabinCategoryCode,
    cabinNumber,
    deckName,
    deckNumber,
    position
  ) {
    this.position = position;
    this.deckNumber = deckNumber;
    this.deckName = deckName;
    this.cabinNumber = cabinNumber;
    this.cabinCategoryCode = cabinCategoryCode;
    this.measurementInfos = measurementInfos;
    this.configurations = configurations;
    this.equipmentDescription = equipmentDescription;
  }
}

class CruiseViewItem {
  constructor(
    arrivalLocationCode,
    arrivalLocationName,
    vendorCode,
    vendorName,
    shipCode,
    shipName,
    departureLocationCode,
    departureLocationName,
    duration,
    portsOfCallQuantity,
    regionCode,
    regionName,
    subRegionCode,
    startDate,
    cruisePackageCode
  ) {
    this.arrivalLocationCode = arrivalLocationCode;
    this.arrivalLocationName = arrivalLocationName;
    this.vendorCode = vendorCode;
    this.vendorName = vendorName;
    this.shipCode = shipCode;
    this.shipName = shipName;
    this.departureLocationCode = departureLocationCode;
    this.departureLocationName = departureLocationName;
    this.duration = duration;
    this.portsOfCallQuantity = portsOfCallQuantity;
    this.regionCode = regionCode;
    this.regionName = regionName;
    this.subRegionCode = subRegionCode;
    this.startDate = startDate;
    this.cruisePackageCode = cruisePackageCode;
  }
}

class ItineraryDescViewItem {
  constructor(
    portCode,
    portName,
    arrivalDateTime,
    departureDateTime,
    arrivalDayOfWeek,
    departureDayOfWeek,
    information
  ) {
    this.portCode = portCode;
    this.portName = portName;
    this.arrivalDateTime = arrivalDateTime;
    this.departureDateTime = departureDateTime;
    this.arrivalDayOfWeek = arrivalDayOfWeek;
    this.departureDayOfWeek = departureDayOfWeek;
    this.information = information;
  }
}

class CategoryLocationFareViewItem {
  constructor(locationName, startDate, amount, currencyCode, shipCode) {
    this.locationName = locationName;
    this.startDate = startDate;
    this.amount = amount;
    this.currencyCode = currencyCode;
    this.shipCode = shipCode;
  }
}

/*
 * 位置视图对象，属于一级类别，如，豪华房（Deluxe），
 * 包括位置名，以及该位置类别下的房间类型
 *
 * 对应的数据样例，如下
 * */

/*
此处为CategoryLocationViewItem对象数组的样例
let categoryLocationViewItems = [
  {
    locationName: "Deluxe",
    cabinCategories: [
      {
        cabinCategoryName: "Superior Oceanview Stateroom",
        pricedCategoryCode: "OS",
        priceInfos: [
          {
            breakdownType: "0301C",
            currencyCode: "USD",
            amount: 3313.0,
            fareCode: "M9482013",
            nonRefundableType: "No Restrictions - Refundable",
            promotionDescription: "STANDARD",
            nccfamount: 135.0,
          },
          {
            breakdownType: "0301A",
            currencyCode: "USD",
            amount: 0.0,
            fareCode: "M9482013",
          },
        ],
      },
    ],
  },
];
* */
class CategoryLocationViewItem {
  /*
   * @param locationName string 位置名，例如，Deluxe
   * @param cabinCategories array 是CabinCategoryViewItem对象数组
   * */
  constructor(locationName, cabinCategories) {
    this.locationName = locationName;
    this.cabinCategories = cabinCategories;
  }
}

class CabinCategoryViewItem {
  /*
   * @param cabinCategoryName string 房型名，例如，Superior Oceanview Stateroom
   * @param pricedCategoryCode string 房型代码，例如，OS
   * @param priceInfos string 价格详情，从Category List步骤的响应中拿
   * */
  constructor(cabinCategoryName, pricedCategoryCode, priceInfos) {
    this.cabinCategoryName = cabinCategoryName;
    this.pricedCategoryCode = pricedCategoryCode;
    this.priceInfos = priceInfos;
  }
}
class PriceBookingDetailsViewItem {
  constructor(bookingPrices, paymentSchedule, guestPrices, reservationIDs) {
    this.bookingPrices = bookingPrices;
    this.paymentSchedule = paymentSchedule;
    this.guestPrices = guestPrices;
    this.reservationIDs = reservationIDs;
  }
}
//endregion 构造视图对象
export default {
  getSailAvailRQ,
  SailAvailFormModel,
  getCruiseViewItems,
  CruiseViewItem,
  getItineraryDescRQ,
  getCategoryListRQ,
  getCabinAvailRQ,
  getCabinHoldRQ,
  getPriceBookingRQ,
  getBookRQ,
  ItineraryDescViewItem,
  CategoryLocationFareViewItem,
  CabinCategoryViewItem,
  getCategoryLocationViewItems,
  getRoomInfoList,
  getCabinDetailRQ,
  getDiningAvailRQ,
  RoomGuestInfo,
  PriceBookingDetailsViewItem,
};
