/*开始 列表页相关*/
function getGroundAvailRQ(tripType, pickupLocationType, pickupLocationCode, dropoffLocaitonType, dropoffLocationCode,
                          adultNum, childNum, infantNum, pickupDatetime, returnDatetime, childAges) {

    let services = [{
        "pickup": {
            "dateTime": pickupDatetime,
            "address": {
                "locationType": {
                    "code": pickupLocationCode,
                    "type": pickupLocationType
                }
            }
        },
        "dropoff": {
            "address": {
                "locationType": {
                    "code": dropoffLocationCode,
                    "type": dropoffLocaitonType
                }
            }
        }
    }];

    if ('ROUND' == tripType){
        services.push({
            "pickup": {
                "dateTime": returnDatetime,
                "address": {
                    "locationType": {
                        "code": dropoffLocationCode,
                        "type": dropoffLocaitonType
                    }
                }
            },
            "dropoff": {
                "address": {
                    "locationType": {

                        "code": pickupLocationCode,
                        "type": pickupLocationType
                    }
                }
            }
        })
    }

    let passengers = [
        {
            "age": 18,
            "quantity": parseInt(adultNum)
        },
        {
            "age": 1,
            "quantity": parseInt(infantNum)
        }];
    if (childAges!=null && childAges.length>0){
        childAges.forEach(childAge => {
            passengers.push(
                {
                    "age": childAge,
                    "quantity": 1
                },
            )
        })
    }

    return {
        "services": services,
        "passengers": passengers
    }
}

function getTransfersInfo(groundAvailRS) {
    let transfersInfo = {
    };
    if (groundAvailRS.success || (groundAvailRS.groundServices && groundAvailRS.groundServices.length>0)){

        let tripMap ={};

        let instance = groundAvailRS.groundServices[0].reference.instance;
        let searchDatetimes = instance.split('-').splice(4);
        let searchDatetime = searchDatetimes.join('-');
        tripMap[searchDatetime] = 'FIRSTTRIP';
        let firstTripPickupDatetimeStr = searchDatetime.replaceAll('T', ' ');
        let firstTripPickupDate = new Date(firstTripPickupDatetimeStr);
        if (groundAvailRS.groundServices.length>1){
            groundAvailRS.groundServices.forEach(groundService => {
                let tripDateTime = groundService.reference.instance.split('-').splice(4).join('-');
                if (!tripMap[tripDateTime]){

                    let datetimeStr = tripDateTime.replaceAll('T', ' ');
                    let compareDate = new Date(datetimeStr);
                    if (compareDate.getTime()>firstTripPickupDate.getTime()){
                        tripMap[tripDateTime] = 'SECONDTRIP';
                    }else{
                        tripMap[tripDateTime] = 'FIRSTTRIP';
                        tripMap[searchDatetime] = 'SECONDTRIP';
                    }
                }
            })
        }

        groundAvailRS.groundServices.forEach(groundService => {
            transfersInfo = constructTransferInfo(groundService, transfersInfo, tripMap);
        });
    }

    return transfersInfo;
}

function getCarTransferInfosShow(carTransfers, currentPage, pageSize) {
    let totalPages = carTransfers.length<= pageSize? 1: Math.ceil(carTransfers.length/pageSize);

    let starIndex = pageSize * (currentPage -1);
    let endIndex = carTransfers.length<=pageSize || totalPages==currentPage? carTransfers.length : pageSize*currentPage;
    return carTransfers.slice(starIndex, endIndex);
}
/*结束 列表页相关*/

/*开始  预定页相关*/
function getGroundBookRQ(tripType, resStatus, holder, pickupInfo, departureInfo, confirmationIds) {

    let groundServices = [
        {
            "shuttle": {
                "serviceLocations": [
                    {
                        "dateTime": pickupInfo.date+'T'+pickupInfo.time,
                        "address": {
                            "locationType": {
                                "code": pickupInfo.shuttleCode,
                                "description": pickupInfo.shuttleDirection,
                                "type": pickupInfo.shuttleType
                            }
                        }
                    }
                ]
            },
            "reference": {
                "id": pickupInfo.rateKey
            }
        }
    ];
    if ("ROUND" == tripType){
        groundServices.push({
            "shuttle": {
                "serviceLocations": [
                    {
                        "dateTime": departureInfo.date+'T'+departureInfo.time,
                        "address": {
                            "locationType": {
                                "code": departureInfo.shuttleCode,
                                "description": departureInfo.shuttleDirection,
                                "type": departureInfo.shuttleType
                            }
                        }
                    }
                ]
            },
            "reference": {
                "id": departureInfo.rateKey
            }
        })
    }

    return {
        "resStatus": resStatus,
        "groundReservations": [
            {
                "confirmations": confirmationIds,
                "passenger": {
                    "primary": {
                        "personName": {
                            "givenName": holder.firstName,
                            "surname": holder.lastName,
                            "nameTitle": holder.nameTitle
                        },
                        "telephoneList": [
                            {
                                "phoneNumber": holder.phoneNumber
                            }
                        ],
                        "emailList": [
                            {
                                "stringValue": holder.emailAddress
                            }
                        ]
                    }
                },
                "groundServices": groundServices
            }
        ]
    }
}

/*结束  预定页相关*/

/*开始 通用*/
function getConvertTerminalType(type) {
    let terminalType = '';
    switch (type) {
        case "STATION":
            terminalType = "TRAIN";
            break;
        case "PORT":
            terminalType = "CRUISE";
            break;
        case "IATA":
            terminalType = "FLIGHT";
            break;
        case "ATLAS":
            terminalType = "HOTEL";
            break;
        default:
    }
    return terminalType;
}

function constructTransferInfo(groundService, transfersInfo, tripMap) {
    let service = groundService.service;
    let name = service.vehicleMakeModel.name+"-"+service.vehicleType.description;
    let vehicleType = service.vehicleType.description;
    let price = groundService.totalCharge.estimatedTotalAmount;
    let currencyCode = groundService.totalCharge.currencyCode;
    let pictureURL = service.vehicleType.sourceURL;
    let adults = service.adults;
    let children = service.children;
    let infants = service.infants;
    let minimumPassengers = service.minimumPassengers;
    let maximumPassengers = service.maximumPassengers;

    let location = service.location;
    let pickup = location.pickup;
    let dateTimes = pickup.dateTime.split('T');
    let date = dateTimes[0];
    let time = dateTimes[1];
    let pickupAddress = pickup.address;
    let pickupDescription = pickupAddress.locationType.description;
    let terminalType = getConvertTerminalType(pickupAddress.locationType.type);
    let pickupAddressLine = pickupAddress.addressLines[0];
    let remarks = pickupAddress.remark;

    let dropoff = location.dropoff;
    let dropoffDescription = dropoff.address.locationType.description;
    let dropoffTerminalType = getConvertTerminalType(dropoff.address.locationType.type);

    let instance = null;
    let rateKey = null;
    if (groundService.reference){
        instance = groundService.reference.instance;
        rateKey = groundService.reference.id;
    }

    let searchDatetime =null;
    let terminalDirection = null;
    if (instance){
        let searchDatetimes = instance.split('-').splice(4);
        searchDatetime = searchDatetimes.join('-');
        terminalDirection = tripMap[searchDatetime];
    }else {
        terminalDirection = tripMap[pickup.dateTime];
    }

    let multimediaDescription = service.multimediaDescriptions.multimediaDescriptionLists[0];
    let textItems = multimediaDescription.textItems;

    let checkPickup = pickup.checkPickup;

    let shuttle = groundService.shuttle;

    // let paymentRules = groundService.paymentRules.paymentRules;

    // let terminalDirection = groundService.shuttle.serviceLocations[0].address.locationType.description;
    if (!transfersInfo[terminalDirection]){
        transfersInfo[terminalDirection]={
            pickupLocation: pickupDescription+"("+terminalType+")",
            dropoffLocation: dropoffDescription+"("+ dropoffTerminalType+")",
            searchDatetime: searchDatetime,
            pickupDate: date,
            pickupTime: time,
            minPrice: 0,
            maxPrice: 0,
            transfers:[],
        };
    }

    transfersInfo[terminalDirection].currencyCode = currencyCode;
    if (transfersInfo[terminalDirection].minPrice==0 || transfersInfo[terminalDirection].minPrice>price){
        transfersInfo[terminalDirection].minPrice = price;
    }
    if (transfersInfo[terminalDirection].maxPrice==0 || transfersInfo[terminalDirection].maxPrice<price){
        transfersInfo[terminalDirection].maxPrice = price;
    }

    let infos = [];
    let infoTypeMap = {};
    textItems.forEach(textItem => {

        if (infoTypeMap[textItem.category]==null){
            infoTypeMap[textItem.category] = {
                type: textItem.category,
                infos: []
            }
        }

        let description = textItem.descriptionList&&textItem.descriptionList.length>0?
            textItem.descriptionList[0].stringValue.replaceAll('\n', '<br>'):'';

        let info = {
            title: textItem.title,
            description:description
        };
        infoTypeMap[textItem.category].infos.push(info);
    });

    for (let key in infoTypeMap){
        infos.push(infoTypeMap[key]);
    }

    let transfer = {
        name: name,
        vehicleType: vehicleType,
        price: price,
        currencyCode: currencyCode,
        minimumPassengers: minimumPassengers,
        maximumPassengers: maximumPassengers,
        pictureURL: pictureURL,
        adultNum: adults,
        childNum: children,
        infantNum: infants,
        pickup: {
            location: pickupDescription+"("+terminalType+")",
            type: terminalType,
            address: pickupAddressLine,
            date: date,
            time: time,
            remarks: remarks
        },
        checkPickup: checkPickup,
        rateKey: rateKey,
        shuttle:{
            code: shuttle.serviceLocations[0].address.locationType.code,
            type: shuttle.serviceLocations[0].address.locationType.type,
            direction: shuttle.serviceLocations[0].address.locationType.description,
            dateTime: shuttle.serviceLocations[0].dateTime
        },
        dropoff: {
            location: dropoffDescription+"("+ dropoffTerminalType+")",
            type: dropoffTerminalType
        },
        infos: infos
    };

    if (groundService.paymentRules && groundService.paymentRules.paymentRules){
        let absoluteDeadline = groundService.paymentRules.paymentRules[0].absoluteDeadline;
        transfer.absoluteDeadline = absoluteDeadline;
    }

    transfersInfo[terminalDirection].transfers.push(transfer);
    transfersInfo[terminalDirection].bookingGroundItemId = groundService.reference.id;

    return transfersInfo;
}

/*结束 通用*/

export default {
    getGroundAvailRQ,
    getGroundBookRQ,
    getTransfersInfo,
    getCarTransferInfosShow,
    constructTransferInfo
}
