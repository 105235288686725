export default {
  namespaced: true,
  state: {
    cancelTokenArr: [], // 取消请求token数组
  },
  mutations: {
    pushToken(state, payload) {
      state.cancelTokenArr.push(payload.cancelToken);
    },
    clearToken({ cancelTokenArr }) {
      //console.log(cancelTokenArr);
      cancelTokenArr.forEach((item) => {
        if (item) {
          item("路由跳转取消请求");
        }
      });
      cancelTokenArr = [];
    },
    emptyToken(state) {
      state.cancelTokenArr = [];
    },
  },
};
