import state from "@/store/modules/package/state";
import getters from "@/store/modules/package/getters";
import actions from "@/store/modules/package/actions";
import mutations from "@/store/modules/package/mutations";

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
