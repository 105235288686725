//#region 获取请求对象

import dateUtils from "@/utils/DateUtils";

function getDocumentQuoteRQ(fromCountryCode, toCountryCode) {
  return {
    fromCountryCode: fromCountryCode,
    toCountryCode: toCountryCode,
  };
}

function getDocumentBookRQ(
  arrivalDate,
  departureDate,
  emailAddress,
  guestInfos,
  documentTypeId
) {
  const travelers = [];
  guestInfos.forEach((guestInfo) => {
    let traveler = {
      gender: guestInfo.gender.toLowerCase(),
      personName: {
        // namePrefix: "Mr",
        givenName: guestInfo.givenName,
        surname: guestInfo.surname,
      },
      documents: [
        {
          docID: guestInfo.docID,
          docType: "passport",
          expireDate: dateUtils.formatDate(guestInfo.docExpireDate),
          docIssueCountry: guestInfo.docHolderNationality,
        },
      ],
      birthDate: guestInfo.birthDate,
    };

    travelers.push(traveler);
  });

  return {
    resStatus: "hold",
    startDate: arrivalDate,
    endDate: departureDate,
    documentTypeId: documentTypeId,
    travelers: travelers,
  };
}

function getTravelDocBookRQ(traveldocBookModel, uniqueIDs) {
  const guestInfos = traveldocBookModel.guestInfos;
  const arrivalDate = traveldocBookModel.arrivalDate;
  const departureDate = traveldocBookModel.departureDate;
  const documentTypeId = traveldocBookModel.documentTypeId;

  const travelers = [];
  guestInfos.forEach((guestInfo) => {
    let traveler = {
      gender: guestInfo.gender.toLowerCase(),
      personName: {
        // namePrefix: "Mr",
        givenName: guestInfo.givenName,
        surname: guestInfo.surname,
      },
      documents: [
        {
          docID: guestInfo.docID,
          docType: "passport",
          expireDate: dateUtils.formatDate(guestInfo.docExpireDate),
          docIssueCountry: guestInfo.docHolderNationality,
        },
      ],
      birthDate: guestInfo.birthDate,
    };

    travelers.push(traveler);
  });

  return {
    resStatus: "book",
    uniqueIDs: uniqueIDs,
    startDate: arrivalDate,
    endDate: departureDate,
    documentTypeId: documentTypeId,
    travelers: travelers,
  };
}
//#endregion 获取请求对象

//#region 获取视图对象
function getDocumentViewItems(services) {
  if (services == null || services.length === 0) return null;

  let items = [];
  services.forEach((service) => {
    service.requiredDocs.forEach((requiredDoc) => {
      const validityNumber = requiredDoc.validityNumber;
      const validityUnit = requiredDoc.validityUnit;
      const title = requiredDoc.title;
      const numberOfEntries = requiredDoc.numberOfEntries;
      const maxStay = requiredDoc.maxStay;
      const maxStayUnit = requiredDoc.maxStayUnit;
      const requirement = requiredDoc.requirement;
      const netAmountTypeId = requiredDoc.netAmountTypeId;
      const netAmount = requiredDoc.netAmount;
      const sellingAmount = requiredDoc.sellingAmount;
      const netCurrencyUomId = requiredDoc.netCurrencyUomId;
      const sellingCurrencyUomId = requiredDoc.sellingCurrencyUomId;

      let amountType = netAmountTypeId;
      switch (netAmountTypeId) {
        case "GOVNT_FEE":
          amountType = "Government Fee";
          break;
        default:
      }

      let validityUnitName = validityUnit;
      switch (validityUnit) {
        case "Y":
          validityUnitName = "Year";
          break;
        case "D":
          validityUnitName = "Day";
          break;
        default:
      }

      let maxStayUnitName = maxStayUnit;
      switch (maxStayUnit) {
        case "Y":
          maxStayUnitName = "Year";
          break;
        case "D":
          maxStayUnitName = "Day";
          break;
        default:
      }

      const cost = service.cost;
      const charges = cost.charges;
      let totalAmount = null;
      let currency = null;
      if (charges && charges.length > 0) {
        charges.forEach((item) => {
          totalAmount += item.amount;
          currency = item.currencyCode;
        });
      }

      const documentType = service.documentType;
      let documentTypeId = documentType.documentTypeId;

      const documentViewItem = new DocumentViewItem(
        validityNumber,
        validityUnitName,
        title,
        numberOfEntries,
        maxStay,
        maxStayUnitName,
        requirement,
        amountType,
        sellingAmount ? sellingAmount : netAmount,
        sellingCurrencyUomId ? sellingCurrencyUomId : netCurrencyUomId,

        totalAmount,
        currency,
        documentTypeId
      );
      items.push(documentViewItem);
    });
  });

  return items;
}

class DocumentViewItem {
  constructor(
    validityNumber,
    validityUnit,
    title,
    numberOfEntries,
    maxStay,
    maxStayUnit,
    requirement,
    amountTypeId,
    typeAmount,
    CurrencyUomId,
    amount,
    currencyCode,
    documentTypeId
  ) {
    this.validityNumber = validityNumber;
    this.validityUnit = validityUnit;
    this.title = title;
    this.numberOfEntries = numberOfEntries;
    this.maxStay = maxStay;
    this.maxStayUnit = maxStayUnit;
    this.requirement = requirement;
    this.amountTypeId = amountTypeId;
    this.typeAmount = typeAmount;
    this.CurrencyUomId = CurrencyUomId;
    this.amount = amount;
    this.currencyCode = currencyCode;
    this.documentTypeId = documentTypeId;
  }
}
//#endregion 获取视图对象
export default {
  getDocumentQuoteRQ,
  getDocumentBookRQ,
  getTravelDocBookRQ,
  getDocumentViewItems,
};
