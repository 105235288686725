import axios from "@/config/AxiosConfig";

function documentQuote(documentQuoteRQ) {
  let url = "/api/v3/sales/shopping/traveldoc/document-quote";
  return axios.post(url, documentQuoteRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
function documentBook(documentBookRQ) {
  let url = "/api/v3/sales/shopping/traveldoc/document-book";
  return axios.post(url, documentBookRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  documentQuote,
  documentBook,
};
