import { createRouter, createWebHashHistory } from "vue-router";
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  //  登录/注册
  {
    path: "/loginPage",
    name: "loginPage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/account/LoginPage.vue"),
  },
  //  忘记密码
  {
    path: "/forget-password",
    name: "forgetPassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/account/ForgetPasswordPage.vue"),
  },
  //  激活賬戶
  {
    path: "/reactive-account",
    name: "reactiveAccount",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/account/ReactiveAccountPage.vue"),
  },
  //  用户信息
  {
    path: "/userInfo",
    name: "userInfo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/account/UserInfo.vue"),
  },
  //  酒店列表
  {
    path: "/hotelList",
    name: "hotelList",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/hotel/HotelList.vue"),
  },
  //  酒店详情
  {
    path: "/hotelDetails",
    name: "hotelDetails",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/hotel/HotelDetails.vue"),
  },
  //  酒店预订
  {
    path: "/hotelBookingReview",
    name: "hotelBookingReview",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/hotel/HotelBookingReview.vue"
      ),
  },
  //  历史订单详情
  {
    path: "/bookingDetail",
    name: "bookingDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/booking/BookingDetails.vue"
      ),
  },
  //  预订成功
  {
    path: "/bookingSuccess",
    name: "bookingSuccess",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/booking/BookingSuccess.vue"
      ),
  },
  //  机票列表
  {
    path: "/flightList",
    name: "flightList",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/flight/FlightList.vue"),
  },
  // 机票订单预览
  {
    path: "/flightTripReview",
    name: "flightTripReview",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/flight/FlightTripReview.vue"
      ),
  },
  // 机票乘客信息
  // FlightPassengers
  {
    path: "/flightPassengers",
    name: "flightPassengers",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/flight/FlightPassengers.vue"
      ),
  },
  // 机票辅助服务
  {
    path: "/flightAncillaryServices",
    name: "flightAncillaryServices",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/flight/FlightAncillaryServices.vue"
      ),
  },
  // 机票预定预览页
  // FlightBookingReview
  {
    path: "/flightBookingReview",
    name: "flightBookingReview",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/flight/FlightBookingReview.vue"
      ),
  },
  {
    path: "/flightPayment",
    name: "flightPayment",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/flight/FlightPayment.vue"
      ),
  },
  // 机票座位选择  FlightSeatMap
  {
    path: "/flightSeatMap",
    name: "flightSeatMap",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/flight/FlightSeatMap.vue"
      ),
  },
  // 机票历史订单详情页
  {
    path: "/flightBookingOverview",
    name: "flightBookingOverview",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/book/FlightBookingOverview.vue"
      ),
  },
  // 机票保险
  {
    path: "/flightInsurance",
    name: "flightInsurance",
    component: () => import("../views/flight/FlightInsurance.vue"),
  },
  //  租车列表
  {
    path: "/carRentalList",
    name: "carRentalList",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/carRental/CarRentalList.vue"
      ),
  },
  //  租车详情
  {
    path: "/carRentalDetails",
    name: "carRentalDetails",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/carRental/CarRentalDetails.vue"
      ),
  },
  //  租车预定
  {
    path: "/carRentalBooking",
    name: "carRentalBooking",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/carRental/CarRentalBookingReview.vue"
      ),
  },
  // 车接列表
  {
    path: "/carTransferList",
    name: "carTransferList",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/carTransfer/CarTransferDepartureList.vue"
      ),
  },
  // 车接预定
  {
    path: "/carTransferBooking",
    name: "carTransferBooking",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/carTransfer/CarTransferBookingReview.vue"
      ),
  },
  //  门票列表
  {
    path: "/tourActivityList",
    name: "tourActivityList",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/tourActivity/TourActivityList.vue"
      ),
  },
  //  门票详情
  {
    path: "/tourActivityDetails",
    name: "tourActivityDetails",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/tourActivity/TourActivityDetails.vue"
      ),
  },
  //  门票预定
  {
    path: "/tourActivityBookingReview",
    name: "tourActivityBookingReview",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/tourActivity/TourActivityBookingReview.vue"
      ),
  },
  //  机+酒   酒店列表 PackageHotelList
  {
    path: "/packageHotelList",
    name: "packageHotelList",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/package/PackageHotelList.vue"
      ),
  },
  //  机+酒  酒店详情页  PackageHotelDetails
  {
    path: "/packageHotelDetails",
    name: "packageHotelDetails",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/package/PackageHotelDetails.vue"
      ),
  },
  //  机+酒   机票列表 PackageHotelList
  {
    path: "/packageFlightList",
    name: "packageFlightList",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/package/PackageFlightList.vue"
      ),
  },
  {
    path: "/packageTripReview",
    name: "packageTripReview",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/package/PackageTripReview.vue"
      ),
  },
  {
    path: "/packagePassengers",
    name: "packagePassengers",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/package/PackagePassengers.vue"
      ),
  },
  {
    path: "/packageAncillaryServices",
    name: "packageAncillaryServices",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/package/PackageAncillaryServices.vue"
      ),
  },
  {
    path: "/packageSeatMap",
    name: "packageSeatMap",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/package/PackageSeatMap.vue"
      ),
  },
  {
    path: "/packageInsurance",
    name: "packageInsurance",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/package/PackageInsurance.vue"
      ),
  },
  {
    path: "/packageBookingReview",
    name: "packageBookingReview",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/package/PackageBookingReview.vue"
      ),
  },
  {
    path: "/packagePayment",
    name: "packagePayment",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/package/PackagePayment.vue"
      ),
  },
  // cruise
  {
    path: "/cruiseList",
    name: "cruiseList",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/cruise/CruiseListView.vue"
      ),
  },
  {
    path: "/cruiseItinerary",
    name: "cruiseItinerary",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/cruise/CruiseItineraryView.vue"
      ),
  },
  {
    path: "/cruiseBooking",
    name: "cruiseBooking",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/cruise/CruiseBookingView.vue"
      ),
  },
  // traver document
  {
    path: "/travelDocumentOption",
    name: "travelDocumentOption",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/traverDocument/TravelDocOptionView.vue"
      ),
  },
  //
  {
    path: "/travelDocReviewView",
    name: "travelDocReviewView",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/traverDocument/TravelDocReviewView.vue"
      ),
  },
  // TravelDocBookingView
  {
    path: "/travelDocBookingView",
    name: "travelDocBookingView",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/traverDocument/TravelDocBookingView.vue"
      ),
  },
  {
    path: "/TravelDocPaymentView",
    name: "TravelDocPaymentView",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/traverDocument/TravelDocPaymentView.vue"
      ),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("../views/common/500Page"),
    children: [
      {
        path: "500",
        name: "500",
        component: () => import("../views/common/500Page"),
      },
    ],
  },

  // -----------test
  // {
  //   path: '/test',
  //   name: 'test',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../testPage.vue')
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
