import * as mutationName from "@/store/modules/account/mutationTypes"

const mutations = {
    [mutationName.UPDATE_USER_INFO](state, payload){
        if (payload.profileReadRS!=null && payload.profileReadRS.success){
            state.profileReadRS = payload.profileReadRS;
            let profile = payload.profileReadRS.profiles.profileInfos[0].profileList[0];
            let username = profile.userIDList[0].id;
            let customer = profile.customer;
            let personName = profile.customer.personName;
            let givenName = personName.givenName;
            let surname = personName.surname;
            let nameTitle = personName.nameTitle;

            let telephoneList = customer.telephoneList;
            let phoneNumber = '';
            if (telephoneList!=null && telephoneList.length>0){
                phoneNumber = telephoneList[0].phoneNumber;
            }
            let emailList = customer.emailList;
            let emailAddress = '';
            if (emailList!=null && emailList.length>0){
                emailAddress = emailList[0].stringValue;
            }
            let addressList = customer.addressList;
            let address = '';
            if (addressList!=null && addressList.length>0){
                address = addressList[0].addressLines[0];
            }

            let user = {
                username: username,
                surname: surname,
                givenName: givenName,
                title: nameTitle,
                phoneNumber: phoneNumber,
                emailAddress: emailAddress,
                address: address
            };

            state.user = user;

        }else {
            state.profileReadRS = {};
            state.user={};
        }
    },

};

export default mutations;
