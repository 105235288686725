import { createStore } from "vuex";
import hotel from "@/store/modules/hotel";
import cruise from "@/store/modules/cruise";
import flight from "@/store/modules/flight";
import carrental from "@/store/modules/carrental";
import tourActivity from "@/store/modules/touractivity";
import cartransfer from "@/store/modules/cartransfer";
import booking from "@/store/modules/booking";
import account from "@/store/modules/account";
import insurance from "@/store/modules/insurance";
import dynamicPackage from "@/store/modules/package";
import traveldoc from "@/store/modules/traveldoc";
import axios from "@/store/modules/axios";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    cruise: cruise,
    hotel: hotel,
    carRental: carrental,
    booking: booking,
    account: account,
    carTransfer: cartransfer,
    tourActivity: tourActivity,
    traveldoc: traveldoc,
    flight: flight,
    insurance: insurance,
    package: dynamicPackage,
    axios: axios,
  },
});
