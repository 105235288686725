const state = {
  cruiseViewItems: null,
  itineraryViewItem: null,
  categoryFaresItem: null,
  cruiseBookModel: null,
  cabinInfoList: null,
  cabinDetailsInfo: null,
  diningOptions: null,
  priceBookingDetails: null,
};

export default state;
