import * as mutationName from "@/store/modules/cartransfer/mutationTypes";
import { ElMessage } from "element-plus";
import CarTransferAPI from "@/apis/CarTransferAPI";
import CarTransferUtils from "@/utils/CarTransferUtils";

const actions = {
  searchAvailTransfers({ commit }, payload) {
    CarTransferAPI.groundAvail(
      payload.tripType,
      payload.pickupLocationType,
      payload.pickupLocationCode,
      payload.dropoffLocaitonType,
      payload.dropoffLocationCode,
      payload.adultNum,
      payload.childNum,
      payload.infantNum,
      payload.pickupDatetime,
      payload.returnDatetime,
      payload.childAges
    )
      .then((res) => {
        let groundAvailRS = res.data;

        commit(mutationName.UPDATE_GROUND_SERVICES_INFO, {
          groundAvailRS: groundAvailRS,
        });
      })
      .catch((error) => {
        //console.info(error);
        ElMessage({
          showClose: true,
          message: error.message,
          type: "error",
        });
      });
  },
  paginationTransfers({ commit }, payload) {
    commit(mutationName.UPDATE_GROUND_SERVICES_SHOW, payload);
  },
  bookCarTransfer({ commit }, payload) {
    CarTransferAPI.groundRes(
      payload.tripType,
      payload.resStatus,
      payload.holder,
      payload.pickupInfo,
      payload.departureInfo
    )
      .then((res) => {
        if (res.errors && res.errors.errors) {
          ElMessage({
            showClose: true,
            duration: 5000,
            message: res.errors.errors[0],
            type: "error",
          });
        } else if (res.data.success) {
          let groundBookRQ = CarTransferUtils.getGroundBookRQ(
            payload.tripType,
            payload.resStatus,
            payload.holder,
            payload.pickupInfo,
            payload.departureInfo
          );
          groundBookRQ.groundReservations[0].confirmations =
            res.data.groundReservations[0].confirmations;

          groundBookRQ.resStatus = "Book";
          let carTransferBookRS = res.data;
          commit(mutationName.UPDATE_GROUND_BOOKING_REVIEW_INFO, {
            carTransferBookRS: carTransferBookRS,
            groundBookRQ: groundBookRQ,
          });

          payload.callback();
        }
      })
      .catch((error) => {
        ElMessage({
          showClose: true,
          message: error,
          type: "error",
        });
      });
  },
};

export default actions;
