import * as mutationName from "@/store/modules/traveldoc/mutationTypes";

const mutations = {
  [mutationName.UPDATE_DOCUMENT_VIEW_ITEMS](state, payload) {
    if (payload.documentViewItems) {
      state.documentViewItems = payload.documentViewItems;
    } else {
      state.documentViewItems = null;
    }
  },
};

export default mutations;
