const getters = {
  hotels(state) {
    return state.hotels;
  },
  hotelsShow(state) {
    return state.hotelsShow;
  },
  hotelResultNum(state) {
    return state.resultNum;
  },
  maxHotelPrice(state) {
    return state.maxHotelPrice;
  },
  minHotelPrice(state) {
    return state.minHotelPrice;
  },
  currencyCode(state) {
    return state.currencyCode;
  },
  //酒店详情相关
  hotelSelected(state) {
    // ////console.info(state.hotelSelected);
    return state.hotelSelected;
  },
  roomStaySelected: (state) => (referenceId) => {
    return state.hotelDetailsAvailRS.roomStays.roomStayList.find(
      (roomStay) => roomStay.reference.id === referenceId
    );
  },
  /*酒店预定页相关*/
  hotelRoomBookingInfo(state) {
    return state.hotelRoomBookingInfo;
  },
  preBookRQ(state) {
    return state.preBookRQ;
  },
  totalPayment(state) {
    return state.totalPayment;
  },
  preBookRS(state) {
    return state.preBookRS;
  },
  uniqueIDs(state) {
    if (
      state.preBookRS &&
      state.preBookRS.hotelReservationList &&
      state.preBookRS.hotelReservationList.length > 0 &&
      state.preBookRS.hotelReservationList[0]
    ) {
      return state.preBookRS.hotelReservationList[0].uniqueIDList;
    }
  },
  bookRQ(state) {
    let preBookRQ = JSON.parse(JSON.stringify(state.preBookRQ));
    let preBookRS = state.preBookRS;

    preBookRQ.resStatus = "Book";

    if (
      preBookRQ &&
      preBookRQ.hotelReservationList &&
      preBookRQ.hotelReservationList.length > 0 &&
      preBookRQ.hotelReservationList[0] &&
      preBookRS &&
      preBookRS.hotelReservationList &&
      preBookRS.hotelReservationList.length > 0 &&
      preBookRS.hotelReservationList[0]
    ) {
      preBookRQ.hotelReservationList[0].resStatus = "Book";
      preBookRQ.hotelReservationList[0].uniqueIDList =
        preBookRS.hotelReservationList[0].uniqueIDList;
      preBookRQ.hotelReservationList[0].roomStayList =
        preBookRS.hotelReservationList[0].roomStayList;
    }

    let bookRQ = preBookRQ;
    return bookRQ;
  },
};

export default getters;
