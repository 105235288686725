import * as mutationName from "@/store/modules/touractivity/mutationTypes";
import TourActivityAPI from "@/apis/TourActivityAPI";
import { ElMessage } from "element-plus";
import TourActivityUtils from "@/utils/TourActivityUtils";

const actions = {
  searchAvailActivities({ commit }, payload) {
    TourActivityAPI.tourActivitySearch(
      payload.destinationCode,
      payload.startDate,
      payload.endDate
    )
      .then((res) => {
        let activitiesSearchRS = res.data;
        //console.info(activitiesSearchRS)
        commit(mutationName.UPDATE_ACTIVITIES_INFO, {
          activitiesSearchRS: activitiesSearchRS,
        });
      })
      .catch((error) => {
        //console.info(error);
        ElMessage({
          showClose: true,
          duration: 5000,
          message: error.message,
          type: "error",
        });
      });
  },
  paginationActivities({ commit }, payload) {
    commit(mutationName.UPDATE_ACTIVITIES_SHOW, payload);
  },
  searchAvailActivityDetail({ commit }, payload) {
    return TourActivityAPI.tourActivityAvail(
      payload.tourActivityID,
      payload.startDate,
      payload.endDate,
      payload.participantCounts,
      payload.groupCode
    )
      .then((res) => {
        let tourActivityAvailRS = res.data;
        if (tourActivityAvailRS.tourActivityInfoList) {
          commit(mutationName.UPDATE_ACTIVITY_DETAIL, {
            tourActivityAvailRS: tourActivityAvailRS,
          });
        } else {
          commit(mutationName.UPDATE_ACTIVITY_DETAIL, {
            tourActivityAvailRS: null,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },
  updateSelectedActivityInfo({ commit }, payload) {
    commit(mutationName.UPDATE_SELECTED_ACITIVITY_INFO, {
      selectedActivityInfo: payload.selectedGroup,
    });
  },
  updateContactInfo({ commit }, payload) {
    commit(mutationName.UPDATE_CONTACT_INFO, {
      tourActivityPassengerModel: payload.tourActivityPassengerModel,
    });
  },
  prebookActivity({ commit }, payload) {
    TourActivityAPI.tourActivityPreconfirm(
      payload.rateKey,
      payload.selectedDate,
      payload.holder,
      payload.username
    )
      .then((res) => {
        let data = res.data;
        if (data.success) {
          let tourActivityPreconfirmRQ =
            TourActivityUtils.getTourActivityPreconfirmRQ(
              payload.rateKey,
              payload.selectedDate,
              payload.holder,
              payload.username
            );
          let tourActivityBookRQ = TourActivityUtils.getTourActivityBookRQ(
            tourActivityPreconfirmRQ,
            data
          );
          commit(mutationName.PREBOOK_ACTIVITY, {
            tourActivityPrebookRS: data,
            tourActivityBookRQ: tourActivityBookRQ,
          });
        } else {
          commit(mutationName.PREBOOK_ACTIVITY, {
            tourActivityPrebookRS: null,
          });
          let errorMessage = res.data.errors.errors[0].stringValue;
          ElMessage({
            showClose: true,
            duration: 5000,
            message: errorMessage,
            type: "error",
          });
        }
      })
      .catch((err) => {
        //console.info(err);
        ElMessage({
          showClose: true,
          duration: 5000,
          message: err,
          type: "error",
        });
      });
    commit(mutationName.PREBOOK_ACTIVITY, {
      tourActivityPassengerModel: payload.tourActivityPassengerModel,
    });
  },
};

export default actions;
