
function getProfileCreateRQ(givenName, surname, phoneNumber, emailAddress, username, password) {

    return {
        "profile": {
            "profileType": "1",
            "customer": {
                "gender": "male",
                "personName": {
                    "givenName":givenName,
                    "surname": surname,
                    "nameTitle": "MR"
                },
                "telephoneList": [
                    {
                        "phoneNumber": phoneNumber
                    }
                ],
                "emailList": [
                    {
                        "stringValue": emailAddress
                    }
                ]
            },
            "userIDList": [
                {
                    "pinNumber": password,
                    "id": username
                }
            ]
        }
    }
}


function getProfileReadRQ(username) {
    return {
        "uniqueID": {
            "id": username,
            "id_context": "USERNAME"
        }
    }
}

function getProfileModifyRQ(username, password, givenName, surname, phoneNumber, emailAddress, address) {
    let addressLines = [];
    if (address!=null && address!= ""){
        addressLines.push(address);
    }
    return {
        "profileModify": {
            "customer": {
                "personName": {
                    "givenName": givenName,
                    "surname": surname
                },
                "telephoneList": [
                    {
                        "phoneNumber": phoneNumber
                    }
                ],
                "emailList": [
                    {
                        "stringValue": emailAddress
                    }
                ],
                "addressList": [
                    {
                        "addressLines": addressLines
                    }
                ]
            },
            "userIDList": [
                {
                    "pinNumber": password,
                    "id": username
                }
            ]
        }
    }
}


export default {
    getProfileCreateRQ,
    getProfileReadRQ,
    getProfileModifyRQ
}
