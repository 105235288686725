import state from "@/store/modules/insurance/state";
import getters from "@/store/modules/insurance/getters";
import actions from "@/store/modules/insurance/actions";
import mutations from "@/store/modules/insurance/mutations";

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
